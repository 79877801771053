import { kebabCase } from 'vuetify/lib/util/helpers'

const defaultDefaults = { name: 'name', title: 'title' }

// Generate fields dynamically
const requireContext = require.context('./', true, /(.*(?<!Form))\.vue$/)
export default requireContext.keys()
  .map((relativePath) => {
    const component = requireContext(relativePath).default
    const filename = relativePath.split('/').reverse()[0].split('.')[0]
    const componentName = kebabCase(filename)
    const formComponentName = componentName + '-form'

    // get the default values from field component prop definitions
    const defaults = { ...defaultDefaults }
    for (const prop of Object.keys(component.props)) {
      const defaultValue = component.props[prop].default()
      if (defaultValue) {
        defaults[prop] = defaultValue
      }
    }

    return {
      name: componentName,
      title: component.name.substring(4),
      defaultValue: component.props.value.default(),
      preview: component.preview,
      description: component.description,
      icon: component.icon,
      skeleton: component.skeleton,
      props: component.props,
      defaults,
      published: component.published !== false,
      formComponentName
    }
  })
  .filter(x => x.published)
