<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    dark
    :mini-variant="$vuetify.breakpoint.smAndUp && mini"
    width="250"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
  >
    <template #img>
      <v-img
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
        height="100%"
        gradient="135deg, #3fed81, #41b883"
        opacity="0.1"
      />
    </template>
    <v-list nav>
      <v-list-item exact :to="{ name: 'index' }">
        <v-list-item-icon v-show="mini" class="hidden-xs-only my-0" style="height: 44px;">
          <div class="d-flex flex-column">
            <span class="title font-weight-bold">ful</span>
            <span class="caption mt-n2" style="opacity: 0.7">βeta</span>
          </div>
        </v-list-item-icon>
        <v-list-item-content class="py-0">
          <v-list-item-title class="title mb-0">
            fire<span class="font-weight-bold">ful</span>
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            version {{ require('@/../package.json').version }} βeta
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider light />
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :to="item.click ? null : { ...item.to, params: { id: project.id } }"
        active-class="primary"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-text="item.subtitle" />
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        active-class="primary"
        @click="help = !help"
      >
        <v-list-item-icon>
          <v-icon>
            mdi-help-circle-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            Help
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list class="v-navigation-drawer__footer">
      <v-divider light />
      <!-- Mini -->
      <v-list-item
        three-line
        class="align-center"
        style="justify-content: center!important;"
      >
        <!-- <v-list-item-content v-if="!mini">
          <v-list-item-subtitle>
            This panel is only visible to your admin users.
          </v-list-item-subtitle>
        </v-list-item-content> -->
        <v-spacer v-if="!mini" />
        <v-btn
          icon
          class="hidden-sm-and-down"
          @click="mini = !mini"
        >
          <v-icon>
            mdi-chevron-{{ mini ? 'right' : 'left' }}
          </v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ProjectNavigation',
  data: () => ({
    mini: false,
    help: false,
    items: [
      {
        icon: 'mdi-home',
        title: 'Content Studio',
        subtitle: 'Edit your content',
        to: { name: 'project-studio' }
      },
      {
        icon: 'mdi-book-variant-multiple',
        title: 'Schema',
        subtitle: 'Edit collections and fields',
        to: { name: 'collections' }
      },
      // {
      //   icon: 'mdi-clipboard-list-outline',
      //   title: 'Fieldset Schemas',
      //   to: { name: 'fieldsets' }
      // },
      {
        icon: 'mdi-folder-multiple-image',
        title: 'Storage Library',
        to: { name: 'project-storage' }
      },
      // {
      //   icon: 'mdi-account-group-outline',
      //   title: 'Team',
      //   to: { name: 'project-team' }
      // },
      {
        icon: 'mdi-cog',
        title: 'Settings',
        to: { name: 'project-settings' }
      }
      // {
      //   icon: 'mdi-receipt',
      //   title: 'Plan',
      //   to: { name: 'project-plan' }
      // }
    ]
  }),
  computed: {
    ...mapGetters(['project']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(drawerState) {
        this.$store.commit('SET_DRAWER', drawerState)
      }
    }
  },
  watch: {
    help: {
      handler(help) {
        if (help) {
          window.tidioChatApi.open()
        } else {
          window.tidioChatApi.close()
        }
      },
      immediate: true
    }
  },
  mounted() {
    window.tidioChatApi.on('open', () => {
      document.getElementsByTagName('html')[0].classList.add('help')
    })
    window.tidioChatApi.on('close', () => {
      document.getElementsByTagName('html')[0].classList.remove('help')
    })
  }
}
</script>

<style lang="sass">
// hide tidio chat
html:not(.help) #tidio-chat
  opacity: 0
  z-index: -1
  display: none
nav.v-navigation-drawer
  > .v-navigation-drawer__image
    opacity: 0.3
  .v-navigation-drawer__footer
    position: absolute
    bottom: 0
    left: 0
    right: 0
</style>
