<template>
  <v-row justify="center">
    <v-col
      v-for="project in projects"
      :key="project.id"
      cols="12"
      sm="6"
      md="4"
      lg="3"
    >
      <v-card
        flat
        outlined
        height="100%"
        @click="$store.dispatch('firebase/bindProject', project)"
      >
        <v-card-title class="justify-center">
          {{ project.name }}
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <v-icon left v-text="'mdi-firebase'" />{{ project.firebaseConfig.projectId }}
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'FireProjects',
  computed: {
    ...mapState(['projects']),
    ...mapGetters(['project']),
    ...mapState('firebase', [
      'app',
      'auth',
      'db',
      'storage',
      'user'
    ])
  }
}
</script>
