import Vue from 'vue'

import App from '@studio/App.vue'
import router from '@studio/router'

import store from '@/store'
import { auth } from '@/plugins/firebase'
import vuetify from '@/plugins/vuetify'
import '@/components'
import '@/plugins'
import '@/filters'
import '@/styles'

Vue.config.productionTip = false

const getAsyncCurrentUser = () => {
  let userLoaded = false
  return new Promise((resolve, reject) => {
    if (userLoaded) {
      resolve(auth.currentUser)
    }
    const unsubscribe = auth.onAuthStateChanged(async(user) => {
      userLoaded = true
      unsubscribe()
      await store.dispatch('setUser', user)
      resolve()
    }, reject)
  })
}

const appendScripts = () => {
// <!-- Hotjar Tracking Code for https://*.fireful.studio -->
  (function(h, o, t, j, a, r) {
    h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) }
    h._hjSettings = { hjid: 2218224, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script'); r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}

const initApp = async() => {
  document.title = 'fireful studio'
  appendScripts()
  auth.onAuthStateChanged((user) => {
    store.dispatch('setUser', user)
  })
  await getAsyncCurrentUser()
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
initApp()
