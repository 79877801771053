<template>
  <v-list>
    <draggable
      :list="filteredFields"
      ghost-class="ghost"
      chosen-class="chosen"
      drag-class="drag"
      animation="200"
      :sort="false"
      :disabled="oneClick"
      :group="{ name: 'fields', pull: 'clone', put: false }"
      :clone="clone"
    >
      <v-hover
        v-for="field in filteredFields"
        :key="field.name"
        v-slot="{ hover }"
      >
        <v-list-item
          dense
          class="pl-1 align-center cursor-grab"
          @click="oneClick ? selectField(field) : null"
        >
          <v-list-item-icon>
            <v-icon v-text="field.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ field.title }}
            </v-list-item-title>
            <v-list-item-subtitle class="two-line">
              {{ field.description }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="oneClick" class="my-0">
            <v-scroll-x-reverse-transition>
              <v-btn
                v-show="oneClick || hover"
                fab
                small
                :dark="!oneClick"
                color="primary"
                @click.stop="selectField(field)"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-scroll-x-reverse-transition>
          </v-list-item-action>
        </v-list-item>
      </v-hover>
    </draggable>
  </v-list>
</template>

<script>
import Draggable from 'vuedraggable'
import { v1 as uuidv1 } from 'uuid'
import fields from './fields'
export default {
  name: 'FireFieldsSelector',
  components: {
    Draggable
  },
  props: {
    oneClick: {
      type: Boolean,
      default: () => false
    },
    filter: {
      type: String,
      default: () => null
    }
  },
  computed: {
    filteredFields() {
      if (!this.filter) {
        return fields
      }
      return fields.filter((field) => {
        return field.name.includes(this.filter) || field.description.includes(this.filter)
      })
    }
  },
  methods: {
    selectField(field) {
      this.$emit('select', this.clone(field))
    },
    clone(original) {
      return {
        id: uuidv1(),
        _type: original.name,
        ...original.defaults
      }
    }
  }
}
</script>

<style lang="sass">
#fireful .v-application
  .flip-list-move
    transition: transform 0.5s
  .ghost
    opacity: 0.5
    background: #c8ebfb
  .v-list-item__subtitle.two-line
    white-space: initial
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    display: -webkit-box
</style>
