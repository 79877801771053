<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="780"
  >
    <v-card v-if="authError" outlined>
      <v-card-title>
        Firebase Authorization Error Encountered
      </v-card-title>
      <v-card-text>
        <v-alert outlined type="error">
          An error related to your firebase project configuration is preventing fireful from operating normally.
        </v-alert>
        <p>
          <strong>Error details:</strong>
          <br>
          {{ authError.message }} ({{ authError.code }})
        </p>
        <p>
          <strong>What you can try:</strong>
          <br>
          {{ authError.tip }}
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn exact :to="{ name: 'index' }" text color="primary">
          Go back to projects index
        </v-btn>
        <v-btn text color="secondary" @click="reload">
          Try again
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'FirebaseErrors',
  data: () => ({
    filenameExistsCounter: 0,
    uploadTask: null,
    collapsed: false
  }),
  computed: {
    ...mapState('firebase', [
      'authError',
      'user'
    ]),
    dialog: {
      get() {
        return this.authError !== null
      },
      set(val) {
        // do nothing
      }
    }
  },
  methods: {
    reload() {
      window.location.reload()
    }
  }
}
</script>
