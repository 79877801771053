import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: 'https://162ac91f71b74d6582e2adb8a1115f3c@o190932.ingest.sentry.io/5428246',
  environment: process.env.NODE_ENV,
  disabled: process.env.NODE_ENV === 'development',
  debug: false,
  logErrors: true,
  integrations: [
    new Integrations.BrowserTracing()
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  tracingOptions: {
    trackComponents: true
  }
})

Vue.prototype.$Sentry = Sentry
if (process.env.NODE_ENV === 'development') {
  window.$Sentry = Sentry
}
