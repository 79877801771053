<template>
  <draggable
    :list="input"
    ghost-class="ghost"
    animation="200"
    direction="vertical"
    empty-insert-threshold="60"
    class="fire-draggable--draggable"
    handle=".draggable"
  >
    <v-card
      v-for="(item, index) in input"
      :key="index"
      outlined
      class="d-flex mb-1"
      @click="$emit('edit-item', item)"
    >
      <v-avatar class="draggable" size="60">
        <v-icon>
          mdi-drag-variant
        </v-icon>
      </v-avatar>
      <v-list-item
        dense
        class="pl-1 align-center"
        style="max-width: calc(100% - 60px);"
      >
        <v-list-item-icon v-if="$scopedSlots['item.icon'] || itemIcon || itemPhoto">
          <v-avatar>
            <slot name="item.icon" :item="item">
              <v-img
                v-if="itemPhoto"
                :src="item[itemPhoto] | fireImage"
                contain
              />
              <v-icon
                v-else-if="itemIcon"
                v-text="item[itemIcon]"
              />
            </slot>
          </v-avatar>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <slot name="item.title" :item="item">
              {{ itemTitle ? item[itemTitle] : item }}
            </slot>
          </v-list-item-title>
          <v-list-item-subtitle>
            <slot name="item.subtitle" :item="item">
              {{ item[itemSubtitle] }}
            </slot>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="deleteItem(item)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable'
export default {
  components: {
    Draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    itemIcon: {
      type: String,
      default: () => null
    },
    itemPhoto: {
      type: String,
      default: () => null
    },
    itemTitle: {
      type: String,
      default: () => null
    },
    itemSubtitle: {
      type: String,
      default: () => null
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(list) {
        this.$emit('input', list)
      }
    }
  },
  methods: {
    layoutUpdated(newLayout) {
      const newValue = newLayout.sort((a, b) => a.y - b.y).map(x => x.item)
      this.$emit('input', newValue)
    },
    deleteItem(item) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$emit('input', this.input.filter(x => x !== item))
      }
    }
  }
}
</script>
