<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    slim
  >
    <fire-card
      :title="label"
      :description="description"
      :error-messages="errors"
      :disabled="disabled"
    >
      <storage-field
        v-slot="{ progress, filename }"
        v-model="input"
        :label="label"
        asset-type="document"
        :extensions="extensions"
        @error="errors.push($event)"
      >
        <v-avatar
          color="grey"
          tile
          size="200"
        >
          <v-img
            max-width="200"
            max-height="200"
            contain
          >
            <div class="asset-preview--document">
              <div class="d-flex flex-column align-center justify-center">
                <v-icon dark>
                  mdi-file-outline
                </v-icon>
                <div class="caption white--text text-truncate pa-2">
                  {{ filename }}
                </div>
              </div>
            </div>
            <template #loading>
              <v-container class="fill-height d-flex justify-center align-center">
                <v-progress-circular indeterminate />
              </v-container>
            </template>
            <template v-if="progress">
              <v-container class="fill-height d-flex justify-center align-center">
                <v-progress-circular :value="progress" />
              </v-container>
            </template>
          </v-img>
        </v-avatar>
      </storage-field>
    </fire-card>
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireDocument',
  description: 'Upload to or choose a document from firebase storage.',
  icon: 'mdi-file-document-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => 'document'
    },
    title: {
      type: String,
      default: () => 'Document'
    },
    extensions: {
      type: Array,
      default: () => []
    },
    size: {
      type: Number,
      default: () => null
    }
  },
  computed: {
    additionalRules() {
      if (!this.extensions.length) {
        return {}
      }
      return {
        extensions: {
          oneOf: this.extensions
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
  .asset-preview--document
    position: absolute
    width: 100%
    height: 100%
    > div
      position: relative
      width: 100%
      height: 100%
      .v-icon
        font-size: 150px
      > div
        max-width: 100%
</style>
