import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/performance'

export const firebaseConfig = {
  apiKey: 'AIzaSyAAz8upMdrbt-GF3fv0xZEMepaXkYgYkOA',
  authDomain: 'fireful-main.firebaseapp.com',
  databaseURL: 'https://fireful-main.firebaseio.com',
  projectId: 'fireful-main',
  storageBucket: 'fireful-main.appspot.com',
  messagingSenderId: '82612859291',
  appId: '1:82612859291:web:6d80fc63c60520b4acb13e'
}

export const { initializeApp } = firebase
if (!firebase.apps.length) {
  initializeApp(firebaseConfig)
}

// authentication
export const { GoogleAuthProvider } = firebase.auth
export const auth = firebase.auth()

// firestore
export const db = firebase.firestore()

export const { Timestamp, GeoPoint, FieldValue } = firebase.firestore
export const getFirestoreDoc = (doc) => {
  const item = { id: doc.id }
  const data = doc.data()
  for (const key of Object.keys(data)) {
    const value = data[key]
    item[key] = typeof value.toDate === 'function' ? value.toDate() : value
  }
  return item
}

// storage
export const { TaskState } = firebase.storage
export const storage = firebase.storage()

// functions
export const functions = firebase.functions()

if (process.env.NODE_ENV === 'development') {
  // auth.useEmulator('http://localhost:9099/')
  functions.useFunctionsEmulator('http://localhost:5001')
  db.useEmulator('localhost', 5003)
}

export const analytics = process.env.NODE_ENV === 'production'
  ? firebase.analytics()
  : {}

export const performance = process.env.NODE_ENV === 'production'
  ? firebase.performance()
  : {}
