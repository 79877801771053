import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { vuexfireMutations, firestoreAction } from 'vuexfire'

import firebase from './modules/firebase'
import uploader from './modules/uploader'
import snackbar from './modules/snackbar'

import { auth, db } from '@/plugins/firebase'

Vue.use(Vuex)

// state
export const state = () => ({
  drawer: null,
  sidebar: null,
  user: null,
  profile: null,
  project: null,
  projects: []
})

// getters
export const getters = {
  project: (state) => {
    // single project in studio context
    if (state.project) {
      if (!state.project.id && state.firebase.app.name_) {
        state.project.id = state.firebase.app.name_
      }
      return state.project
    }
    if (state.firebase.app && state.firebase.app.name_) {
      return state.projects.find(x => x.id === state.firebase.app.name_) || null
    }
    return null
  }
}

// mutations
export const mutations = {
  SET_DRAWER(state, open) {
    state.drawer = !!open
  },
  SET_SIDEBAR(state, open) {
    state.sidebar = !!open
  },
  SET_USER(state, user) {
    const currentUser = user || auth.currentUser
    if (!currentUser) {
      state.user = null
    } else {
      const { uid, email, emailVerified, displayName, photoURL, phoneNumber } = currentUser
      state.user = { uid, email, emailVerified, displayName, photoURL, phoneNumber }
    }
  },
  SET_PROJECT(state, project) {
    state.project = project
  },
  ...vuexfireMutations
}

// actions
export const actions = {
  setUser: firestoreAction(async({ state, commit, bindFirestoreRef, unbindFirestoreRef }, user) => {
    commit('SET_USER', user)
    if (user) {
      const profile = await db.collection('profiles').doc(user.uid).get()
      !profile.exists && await db.collection('profiles').doc(user.uid).set({})
      await bindFirestoreRef('profile', db.collection('profiles').doc(user.uid))
    } else {
      await unbindFirestoreRef('profile')
    }
    // bind projects if project is not set in studio context
    if (!state.project) {
      if (user && user.email) {
        await bindFirestoreRef('projects', db.collection('projects').where('userEmails', 'array-contains', user.email))
      } else {
        await unbindFirestoreRef('projects')
      }
    }
  }),
  async setProject({ commit, dispatch }, project) {
    commit('SET_PROJECT', project)
    await dispatch('firebase/bindProject', project)
  }
}

export default new Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    firebase,
    uploader,
    snackbar
  }
})
