<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <v-checkbox
      v-model="input"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      hide-details="auto"
      indeterminate
      color="primary"
      class="ma-2 mb-4"
      :required="required"
      :error-messages="errors"
      :disabled="disabled"
    />
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireCheckbox',
  description: 'A boolean checkbox field that can either be true or false.',
  icon: 'mdi-checkbox-marked-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    name: {
      type: String,
      default: () => 'checkbox'
    },
    title: {
      type: String,
      default: () => 'Checkbox'
    }
  },
  computed: {
    additionalRules() {
      const rules = {}
      if (this.required) {
        rules.required = { allowFalse: false }
      }
      return rules
    }
  }
}
</script>
