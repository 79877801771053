<template>
  <validation-provider
    v-slot="{ errors, invalid }"
    ref="input"
    :rules="rules"
    :name="label"
    tag="div"
    class="fire-input"
  >
    <v-text-field
      ref="textInput"
      v-model="textInput"
      :label="label"
      :hint="description"
      :persistent-hint="!!description"
      :hide-details="hideDetails"
      outlined
      :clearable="clearable"
      :error-messages="errors"
      :disabled="disabled"
      @click="menu=true"
      @click:clear="clear"
    >
      <template #append>
        <v-menu
          v-model="menu"
          transition="scale-transition"
          :close-on-content-click="false"
          min-width="290px"
          origin="top right"
          left
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              size="30"
              color="primary"
              rounded
              text
              v-on="on"
            >
              <v-icon
                left
                v-text="'mdi-calendar-clock'"
              />
              Pick
            </v-btn>
          </template>
          <v-card
            style="position: relative;"
            width="290px"
            flat
          >
            <v-btn
              icon
              large
              color="white"
              style="position: absolute; top: 0; right: 0; z-index: 10;"
              @click="menu = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
            <v-toolbar v-if="timePicker" dense flat class="align-center">
              <v-tabs
                v-model="tab"
                fixed-tabs
                dense
              >
                <v-tab>
                  <v-icon>
                    mdi-calendar-month-outline
                  </v-icon>
                </v-tab>
                <v-tab>
                  <v-icon>
                    mdi-calendar-clock
                  </v-icon>
                </v-tab>
              </v-tabs>
              <v-btn
                text
                icon
                @click="menu = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-date-picker
                  ref="datePicker"
                  v-model="date"
                  :min="minDate"
                  :max="maxDate"
                  first-day-of-week="1"
                  :show-current="showCurrentDate"
                  flat
                  class="v-card--flat"
                  @input="inputDate"
                  @change="!timePicker && (menu=false)"
                />
              </v-tab-item>
              <v-tab-item>
                <v-time-picker
                  v-model="time"
                  :min="date === minDate ? minTime : null"
                  :max="date === maxDate ? maxTime : null"
                  format="24hr"
                  flat
                  scrollable
                  :allowed-minutes="m => m % 5 === 0"
                  class="v-card--flat"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-divider />
            <v-card-actions>
              <v-btn
                v-if="clearable"
                text
                @click="clear"
              >
                Clear
              </v-btn>
              <v-spacer />
              <v-btn
                color="primary"
                text
                :disabled="invalid"
                @click="menu=false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
import moment from 'moment'
import { fieldsMixin } from '@/mixins'
import { Timestamp } from '@/plugins/firebase'

export default {
  name: 'FireDatetime',
  description: 'A timestamp field with a datetime picker.',
  icon: 'mdi-calendar-clock',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: [Date, String, Object],
      default: () => ''
    },
    name: {
      type: String,
      default: () => 'datetime'
    },
    title: {
      type: String,
      default: () => 'Date and Time'
    },
    timePicker: {
      type: Boolean,
      default: () => true
    },
    dateTimeFormat: {
      type: String,
      default: () => 'YYYY-MM-DD HH:mm'
    },
    startWithYear: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => true
    },
    showCurrentDate: {
      type: Boolean,
      default: () => true
    },
    min: {
      type: [Date, String, Object],
      default: () => ''
    },
    max: {
      type: [Date, String, Object],
      default: () => ''
    },
    hideDetails: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    textInput: '',
    menu: false,
    moment: null,
    tab: 0,
    minDate: null,
    maxDate: null,
    minTime: null,
    maxTime: null
  }),
  computed: {
    date: {
      get() {
        return this.moment ? this.moment.format('YYYY-MM-DD') : null
      },
      set(dateString) {
        if (!this.moment) {
          this.moment = moment(dateString, 'YYYY-MM-DD')
          this.$emit('input', Timestamp.fromDate(this.moment.toDate()))
          return
        }
        const [year, month, date] = dateString.split('-')
        this.moment.set({ year, date, month: month - 1 })
        this.$emit('input', Timestamp.fromDate(this.moment.toDate()))
      }
    },
    time: {
      get() {
        return this.moment ? this.moment.format('HH:mm') : null
      },
      set(timeString) {
        if (this.moment) {
          const [hour, minute] = timeString.split(':')
          this.moment.set({ hour, minute })
          this.$emit('input', Timestamp.fromDate(this.moment.toDate()))
        }
      }
    },
    additionalRules() {
      return { dateformat: this.dateTimeFormat }
    }
  },
  watch: {
    value: {
      handler(value) {
        // Firebase Date Object
        if (value && typeof value === 'object' && typeof value.toDate === 'function') {
          this.moment = moment(value.toDate())
          this.textInput = this.moment.format(this.dateTimeFormat)
          return
        }
        this.moment = null
      },
      immediate: true
    },
    async textInput(text) {
      const { valid } = await this.$refs.input.validate()
      if (valid) {
        this.$emit('input', moment(text, this.dateTimeFormat).toDate())
      }
    },
    menu(menu, oldMenu) {
      menu && this.startWithYear && this.$nextTick(() => {
        this.$refs.datePicker.activePicker = 'YEAR'
      })
      if (this.timePicker && !oldMenu && menu) {
        this.tab = 0
      }
      if (!menu) {
        this.$refs.textInput.focus()
      }
    },
    min: {
      handler(min) {
        if (min) {
          this.minDate = moment(min).format('YYYY-MM-DD')
          this.minTime = moment(min).format('HH:mm')
        }
      },
      immediate: true
    },
    max: {
      handler(max) {
        if (max) {
          this.maxDate = moment(max).format('YYYY-MM-DD')
          this.maxTime = moment(max).format('HH:mm')
        }
      },
      immediate: true
    }
  },
  methods: {
    clear() {
      this.tab = 0
      this.$emit('input', null)
    },
    inputDate() {
      if (this.timePicker) {
        this.tab = 1
      }
    }
  }
}
</script>
