import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'
import moment from 'moment'
import { captureMessage } from '@sentry/vue'
import { db } from './firebase'
import { unpackDownloadURL } from './../utils/helpers'
/* eslint import/namespace: ['error', { allowComputed: true }] */
for (const key of Object.keys(rules)) {
  extend(key, rules[key])
}
localize('en', en)

extend('url', {
  validate: value => value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) !== null,
  message: 'The {_field_} must be a valid url'
})

extend('arrLength', {
  validate: (value, { min, max }) => Array.isArray(value) && (!min || value.length >= min) && (!max || value.length <= max),
  message: (fieldName, { _value_, min, max }) => {
    if (!Array.isArray(_value_)) {
      return `The ${fieldName} list must be an array`
    }
    if (min && _value_.length < min) {
      return `The ${fieldName} list must at have at least ${min} elements`
    }
    if (max && _value_.length > max) {
      return `The ${fieldName} list can at most have ${max} elements`
    }
    captureMessage(`valiation arrLength did not pass for min ${min}, max ${max} and val: ${_value_.join(', ')}`)
    return `The ${fieldName} doesn't pass the arrLength rule.`
  },
  params: ['min', 'max']
})

extend('extensions', {
  validate(value, { oneOf }) {
    const { ext } = unpackDownloadURL(value)
    return ext && oneOf.map(x => x.toLowerCase()).includes(ext.toLowerCase())
  },
  message: (fieldName, { oneOf }) => {
    return `The ${fieldName} must have of one of the following file extensions: ${oneOf.join(', ')}`
  },
  params: ['oneOf']
})

extend('dateformat', {
  validate(value, { format }) {
    return value.length === format.length && moment(value, format).isValid()
  },
  message: (fieldName, { format }) => {
    return `The ${fieldName} must be in the following format: ${format}`
  },
  params: ['format']
})

extend('unique', {
  validate(value, args) {
    const [collectionName, documentId] = args
    return new Promise((resolve) => {
      db.collection(collectionName).where('name', '==', value).get().then(({ docs = [] }) => {
        resolve(docs.filter(x => x.id !== documentId).length === 0)
      })
    })
  },
  message: 'The {_field_} field has to be unique. There is another document with this field in the {collectionName} collection'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
