<template>
  <validation-observer
    ref="form"
    v-slot="{ valid }"
    tag="form"
    @submit.prevent="save"
  >
    <v-card-text>
      <v-alert type="info" border="left" text>
        Add fireful as a new Web App on firebase console and use the newly generated config JSON. <!-- LATER: <a href="">See here</a> for step by step instructions. -->
      </v-alert>
      <fire-text
        v-model="configText"
        title="Firebase Configuration"
        description="Copy and paste your firebaseConfig object here."
        rows="6"
      />
      <fire-string
        v-model="form.name"
        title="Project Name"
        description="The display name for your project"
        required
      />
      <fire-string
        v-model="form.identifier"
        title="Project Identifier"
        description="Your unique firebase project id and your subdomain for the studio. This cannot be changed."
        required
        disabled
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        type="submit"
        :loading="loading"
        :disabled="error !== null || !valid || loading"
      >
        Next
        <v-icon right>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </v-card-actions>
  </validation-observer>
</template>

<script>
import { mapState } from 'vuex'
import slugify from 'slugify'
import { db, Timestamp } from './../plugins/firebase'

export default {
  name: 'FireProjectSetup',
  data() {
    return {
      configurationDialog: false,
      configurationHintDialog: false,
      configText: '',
      form: {
        name: '',
        identifier: ''
      },
      loading: false,
      error: null
    }
  },
  computed: {
    ...mapState(['user']),
    firebaseConfig() {
      const firebaseConfig = {}
      const config = /{((.|\n)*)}/.exec(this.configText)
      if (!config || config.length < 2) {
        return firebaseConfig
      }
      for (const match of config[1].matchAll(/\s*(\S*):\s["'](.*)["']/g)) {
        firebaseConfig[match[1]] = match[2]
      }
      return firebaseConfig
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.error = null
      }
    },
    'firebaseConfig.projectId'(projectId) {
      this.form.identifier = projectId
      if (projectId) {
        this.form.name = slugify(projectId, { lower: true })
      } else {
        this.form.name = ''
      }
    }
  },
  methods: {
    async save() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      const { firebaseConfig } = this
      const { name, identifier } = this.form
      // LATER: Manually initializeApp and test if firebaseConfig works
      // const testApp = initializeApp(firebaseConfig, identifier)
      this.loading = true
      try {
        await db.collection('projects').doc(identifier).set({
          name,
          userEmails: [this.user.email],
          firebaseConfig,
          createdBy: this.user.uid,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date())
        })
        this.$emit('saved', identifier)
        this.$store.dispatch('snackbar/new', { message: 'Project created.' })
      } catch (error) {
        // if permission is denied the project already exists
        if (error.code === 'permission-denied') {
          // LATER: Add a way to add the user to the project if approved
          this.$router.go(-1)
          alert('This project has already been added by someone else. Currently there is nothing you can do. You can contact us directly if needed.')
          return
        }
        this.error = error
        this.$store.dispatch('snackbar/new', { error })
        throw error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
