var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-provider',{ref:"input",staticClass:"fire-input",attrs:{"rules":_vm.rules,"name":_vm.label.toLowerCase(),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('fire-card',{attrs:{"title":_vm.pluralLabel,"description":_vm.description,"disabled":_vm.disabled,"error-messages":errors},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.add}},[_vm._v(" Add "+_vm._s(_vm.title)+" ")])]},proxy:true}],null,true)},[_c('grid-array',{attrs:{"item-photo":_vm.itemPhoto,"item-icon":_vm.itemIcon,"item-title":_vm.itemTitle,"item-subtitle":_vm.itemSubtitle},on:{"edit-item":function($event){return _vm.edit($event)}},scopedSlots:_vm._u([(_vm.$scopedSlots['item.icon'])?{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_vm._t("item.icon",null,{"item":item})]}}:null,(_vm.$scopedSlots['item.title'])?{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._t("item.title",null,{"item":item})]}}:(_vm.ofField && _vm.ofField.preview)?{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ofField.preview(item))+" ")]}}:null,(_vm.$scopedSlots['item.subtitle'])?{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_vm._t("item.subtitle",null,{"item":item})]}}:null],null,true),model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),(!_vm.input.length)?_vm._t("empty",function(){return [(_vm.emptyMessage)?[_vm._v(" "+_vm._s(_vm.emptyMessage)+" ")]:(_vm.pluralLabel)?[_vm._v(" No "+_vm._s(_vm.pluralLabel.toLowerCase())+" have been added so far. ")]:_vm._e()]}):_vm._e()],2)]}}],null,true)}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"900","fullscreen":_vm.fullscreen},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('validation-observer',{ref:"form",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.fullscreen = !_vm.fullscreen}}},[_c('v-icon',[_vm._v(" mdi-fullscreen ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_vm._t("item.edit",function(){return [(_vm.of._type)?_c(_vm.of._type,_vm._b({tag:"component",model:{value:(_vm.editItem),callback:function ($$v) {_vm.editItem=$$v},expression:"editItem"}},'component',_vm.of,false)):_vm._e()]},{"item":_vm.editItem})],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":invalid,"text":""}},[_vm._v(" Save ")])],1)],1)]}}],null,true)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }