import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import vuetify from '@/plugins/vuetify'

Vue.use(Router)

// The middleware for every page of the application.
const globalMiddleware = ['auth', 'project']

/**
 * Create a new router instance.
 *
 * @return {Routerqy}
 */
const createRouter = () => {
  const router = new Router({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior,
    routes
  })
  router.beforeEach(beforeEach)
  // router.afterEach(afterEach)
  return router
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
const resolveMiddleware = (requireContext) => {
  return requireContext.keys()
    .map(file =>
      [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((guards, [name, guard]) => (
      { ...guards, [name]: guard.default }
    ), {})
}

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
  require.context('./middleware', false, /.*\.js$/)
)

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
const scrollBehavior = (to, from, savedPosition) => {
  if (to.name === 'field' || from.name === 'field') {
    return
  }
  let scrollTo = 0
  if (to.hash) {
    scrollTo = to.hash
  } else if (savedPosition) {
    scrollTo = savedPosition.y
  }
  return vuetify.framework.goTo(scrollTo)
}
/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
const beforeEach = async(to, from, next) => {
  // Get the middleware for all the matched components.
  const middleware = getMiddleware(to)

  // Call each middleware.
  await callMiddleware(middleware, to, from, (...args) => {
    next(...args)
  })
}

/**
 * Merge the the global middleware with the route middleware.
 *
 * @param  {Array} to
 * @return {Array}
 */
const getMiddleware = (to) => {
  const middleware = [...globalMiddleware]
  to.matched.filter(record => record.meta && record.meta.middleware).forEach((record) => {
    if (Array.isArray(record.meta.middleware)) {
      middleware.push(...record.meta.middleware)
    } else {
      middleware.push(record.meta.middleware)
    }
  })
  return middleware
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
const callMiddleware = async(middleware, to, from, next) => {
  const stack = middleware.reverse()
  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args)
    }
    const middleware = stack.pop()
    if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw new Error(`Undefined middleware [${middleware}]`)
    }
  }
  await _next()
}

const router = createRouter()
export default router
