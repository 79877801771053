<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <template #validations>
      <div class="fire-input">
        <v-combobox
          v-model="value.extensions"
          :items="extensions"
          label="Allowed Extensions"
          hint="List extensions that you want to support."
          class="fire-input"
          persistent-hint
          multiple
          outlined
          chips
        />
      </div>
      <fire-number
        v-model="value.size"
        title="Max Size"
        description="The file size added to the field under validation must not exceed the specified size in kilobytes."
        integer
        disallow-negative
      />
    </template>
  </fire-field-form>
</template>

<script>
import { extensions } from './index.js'
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireImageForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    extensions,
    field: fields.find(x => x.name === 'fire-video')
  })
}
</script>
