import { db, auth } from '@/plugins/firebase'
import store from '@/store'

export default async(to, from, next) => {
  if (!['404', 'login'].includes(to.name) && !store.state.project) {
    // get the project id from subdomain
    const projectId = process.env.NODE_ENV === 'production' ? window.location.host.split('.')[0] : 'fireful-demo'
    // obtain the project
    try {
      const projectDoc = await db.collection('projects').doc(projectId).get()
      if (!projectDoc.exists) {
        next({ name: '404' })
        return
      }
      await store.dispatch('setProject', projectDoc.data())
    } catch (error) {
      if (error.code === 'permission-denied') {
        error.message = 'You do not have access to this project.'
        store.dispatch('snackbar/new', { error })
        await auth.signOut()
        from.name !== 'login' && next({ name: 'login' })
        return
      }
      throw error
    }
  }
  next()
}
