<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-switch
      v-model="value.timePicker"
      title="Show Time Picker"
    />
    <fire-string
      v-model="value.dateTimeFormat"
      title="Date Time Display Format"
      description="Enter the format to be displayed on the textfield. You can refer to the formats on https://momentjs.com/docs/#/displaying/format/"
    />
    <fire-switch
      v-model="value.clearable"
      title="Clearable"
      description="Shows a clear button on the textfield and the datetimepicker"
    />
    <fire-switch
      v-model="value.startWithYear"
      title="Start With Year"
    />
    <fire-switch
      v-model="value.showCurrentDate"
      title="Show current"
      description="Toggles visibility of the current date/month outline or shows the provided date/month as a current"
    />
    <fire-datetime
      v-model="value.defaultValue"
      title="Default Value"
    />
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireDatetimeForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-datetime')
  }),
  watch: {
    'value.timePicker'(timePicker) {
      if (!timePicker && this.value.dateTimeFormat.endsWith(' HH:mm')) {
        this.value.dateTimeFormat = this.value.dateTimeFormat.replace(' HH:mm', '')
      }
      if (timePicker && !this.value.dateTimeFormat.endsWith(' HH:mm')) {
        this.value.dateTimeFormat = this.value.dateTimeFormat += ' HH:mm'
      }
    }
  }
}
</script>
