export const extensions = [
  'avi',
  'mpg',
  'mpeg',
  'mp4',
  'wmv',
  'webm',
  'mkv',
  '3gp',
  'mov'
]
