<template>
  <div class="fire-column--container fire-column v-dialog--scrollable">
    <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical />
    <v-dialog
      :value="!!permissionsError"
      max-width="960"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
    >
      <v-card v-if="permissionsError" outlined>
        <v-card-title>
          Firestore Rules Error Encountered
        </v-card-title>
        <v-card-text>
          <v-alert outlined type="error">
            A permissions error occured when trying to read your projects firestore data.
          </v-alert>
          <p>
            <strong>Error details:</strong>
            <br>
            {{ permissionsError.message }} ({{ permissionsError.code }})
          </p>
          <p>
            <strong>What you can try:</strong>
            <br>
            Please set your permissions as below
          </p>
          <fire-text
            ref="rulesContainer"
            :value="rules"
            rows="8"
          >
            <template #append>
              <v-btn icon @click="copyRules">
                <v-icon>
                  mdi-content-copy
                </v-icon>
              </v-btn>
            </template>
          </fire-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="reload">
            Try again
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      v-if="collection"
      flat
      class="fire-column"
      :class="{ 'd-none': $vuetify.breakpoint.xsOnly && $route.params.documentId }"
    >
      <v-card-title>
        <v-btn
          v-if="$vuetify.breakpoint.xsOnly"
          icon
          exact
          class="mr-4"
          :to="{ name: 'project-studio' }"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        {{ collection.plural || `${collection.title}s` }}
        <v-spacer />
        <v-btn
          v-if="collection.create !== false"
          icon
          color="primary"
          :to="{ name: 'document', params: { documentId: 'new' } }"
        >
          <v-icon v-text="'mdi-plus'" />
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-list>
          <template v-if="loading">
            <v-skeleton-loader
              v-for="n in 10"
              :key="n"
              type="list-item-avatar-two-line"
              tile
              class="mx-auto"
            />
          </template>
          <fire-document-list-item
            v-for="item in sortedDocuments"
            v-show="!loading"
            :key="item | docKey"
            :item="item"
            :fields="collection.fields"
            :item-avatar="collection.listPreviewAvatar"
            :item-title="collection.listPreviewTitle"
            :item-subtitle="collection.listPreviewSubtitle"
            :to="{ name: 'document', params: { documentId: item.id } }"
          />
          <template v-if="!loading && !documents.length">
            <v-subheader>
              No {{ collection.plural || `${collection.title}s` }}
            </v-subheader>
            <v-btn
              v-if="collection.create !== false"
              class="ma-3"
              color="primary"
              rounded
              text
              :to="{ name: 'document', params: { documentId: 'new' } }"
            >
              <v-icon left v-text="'mdi-plus'" />
              New {{ collection.title }}
            </v-btn>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Documents',
  data: () => ({
    loading: true,
    collapsed: false,
    documents: [],
    unsubscribe: null,
    permissionsError: null
  }),
  computed: {
    ...mapState('firebase', ['db', 'collections']),
    collection() {
      return this.collections.find(x => x.ref === this.$route.params.collectionRef)
    },
    sortedDocuments() {
      const documents = this.documents
      documents.sort((a, b) => {
        const aDate = a.updatedAt ? a.updatedAt.toDate() : 0
        const bDate = b.updatedAt ? b.updatedAt.toDate() : 0
        return bDate - aDate
      })
      return documents
    },
    rules() {
      return `rules_version = '2';
service cloud.firestore {
  match /databases/{database}/documents {
    match /{document=**} {
      allow read, write: if request.auth.token.email == '${this.$route.params.id}@fireful.studio';
    }
  }
}`
    }
  },
  watch: {
    collection: {
      handler(collection, oldCollection) {
        if (collection?.ref !== oldCollection?.ref) {
          this.documents = []
          this.loading = true
          if (this.unsubscribe) {
            this.unsubscribe()
          }
          if (collection) {
            this.unsubscribe = this.db
              .collection(collection.ref)
              .onSnapshot(this.onSnapshot, this.onSnapshotError)
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    onSnapshot({ docs }) {
      this.documents = docs.map(x => ({ id: x.id, ...x.data() }))
      this.loading = false
    },
    onSnapshotError(error) {
      if (error.code === 'permission-denied') {
        this.permissionsError = error
        return
      }
      throw error
    },
    copyRules() {
      this.$copyText(this.rules, this.$refs.rulesContainer.$el)
        .then(() => {
          this.$store.dispatch('snackbar/new', { message: 'Content copied to clipboard', type: 'success' })
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/new', { error })
        })
    },
    reload() {
      window.location.reload()
    }
  }
}
</script>
