import store from '@/store'

export default (to, from, next) => {
  const currentProject = store.getters.project || {}
  if (to.params.id && to.params.id !== currentProject.id) {
    const toProject = store.state.projects.find(x => x.id === to.params.id)
    if (!toProject) {
      next({ name: '404' })
      return
    }
    store.dispatch('firebase/bindProject', toProject)
  }
  if (!to.params.id && currentProject.id) {
    store.dispatch('firebase/bindProject', null)
  }
  next()
}
