<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <fire-card
      :title="label"
      :description="description"
      :disabled="disabled"
      :error-messages="errors"
    >
      <v-text-field
        v-model="latLong"
        label="Latitude, Longitude"
        :hint="hint"
        persistent-hint
        outlined
        clearable
      />
      <v-card v-if="showMap" flat class="mb-5">
        <v-text-field
          ref="searchInput"
          v-model="searchQuery"
          label="Enter a location to search"
          placeholder=""
          hide-details
          color="primary"
          clearable
          filled
        />
        <div ref="map" class="g-coord-picker" />
      </v-card>
    </fire-card>
  </validation-provider>
</template>

<script>
// import { mapState } from 'vuex'
import { fieldsMixin } from '@/mixins'
import { firebaseConfig } from '@/plugins/firebase'
export default {
  name: 'FireGeopoint',
  description: 'Coordinates field with a searchable google maps picker.',
  icon: 'mdi-map-marker-circle',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: Array,
      default: () => [null, null]
    },
    name: {
      type: String,
      default: () => 'geopoint'
    },
    title: {
      type: String,
      default: () => 'Geopoint'
    },
    showMap: {
      type: Boolean,
      default: () => true
    },
    center: {
      type: Array,
      default: () => [7.0747832, 51.1627533]
    },
    zoom: {
      type: Number,
      default: () => 14
    }
  },
  data: () => ({
    searchQuery: '',
    latLongRegex: /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    map: null,
    autocomplete: null,
    marker: null,
    timeout: null
  }),
  computed: {
    // ...mapState('firebase', ['apiKey']),
    hint() {
      return `Enter the latitude and longitude separated by a comma${this.showMap ? ', or find and select the point on the map below.' : '.'}`
    },
    additionalRules() {
      return { regex: this.latLongRegex }
    },
    latLong: {
      get() {
        if (this.value) {
          const [longitude, latitude] = this.value
          if (longitude && latitude) {
            return `${latitude},${longitude}`
          }
        }
        return null
      },
      set(latLong) {
        if (!latLong) {
          this.input = null
          return
        }
        if (latLong.match(this.latLongRegex) !== null) {
          const [latitude, longitude] = latLong.split(',')
          this.input = [parseFloat(longitude), parseFloat(latitude)]
        }
      }
    }
  },
  watch: {
    value: {
      handler() {
        this.setMarker()
      },
      deep: true,
      immediate: true
    },
    showMap: {
      handler(showMap) {
        showMap && this.initMap()
      },
      immediate: true
    },
    zoom(zoom) {
      if (zoom >= 0 && zoom <= 19) {
        this.map.setZoom(zoom)
      }
    }
  },
  beforeMount() {
    const fjs = document.getElementsByTagName('script')[0]
    let js = document.getElementById('google-maps-script')
    if (!js) {
      js = document.createElement('script')
      js.id = 'google-maps-script'
      fjs.parentNode.insertBefore(js, fjs)
    }
    // LATER: Switch to apiKey of project
    // js.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`
    js.src = `https://maps.googleapis.com/maps/api/js?key=${firebaseConfig.apiKey}&libraries=places`
  },
  methods: {
    initMap() {
      const { google } = window
      if (!google || !this.$refs.map) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.initMap, 500)
        return
      }
      const lng = (this.value || [])[0] || this.center[0]
      const lat = (this.value || [])[1] || this.center[1]
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat, lng },
        zoom: this.zoom
      })
      google.maps.event.addListener(this.map, 'click', this.mapOnClick)
      const input = this.$refs.searchInput.$el.getElementsByTagName('input')[0]
      this.autocomplete = new google.maps.places.Autocomplete(input)
      this.autocomplete.setFields(['name', 'geometry'])
      google.maps.event.addListener(this.autocomplete, 'place_changed', this.change)
      this.setMarker()
    },
    setMarker() {
      this.marker && this.marker.setMap(null)
      const [longitude, latitude] = (this.value || [])
      if (!latitude || !longitude) {
        return
      }
      const { google } = window
      if (!this.map || !google) {
        return
      }
      const location = { lat: latitude, lng: longitude }
      this.map.panTo(location)
      this.marker = new google.maps.Marker({
        map: this.map,
        position: location,
        animation: google.maps.Animation.DROP,
        icon: {
          url: require('./map-marker.svg'),
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        }
      })
    },
    change() {
      const { name, geometry } = this.autocomplete.getPlace()
      if (geometry && geometry.location) {
        const { lat, lng } = geometry.location
        this.input = [lng(), lat()]
        this.searchQuery = name
      } else {
        this.searchQuery = ''
      }
      if (this.$refs.textInput && this.$refs.textInput.$el) {
        this.$nextTick(() => {
          this.$refs.textInput.$el.querySelector('input').focus()
          this.$refs.textInput.$el.querySelector('input').blur()
        })
      }
    },
    mapOnClick({ latLng }) {
      const { lat, lng } = latLng
      this.input = [lng(), lat()]
      if (this.$refs.textInput && this.$refs.textInput.$el) {
        this.$nextTick(() => {
          this.$refs.textInput.$el.querySelector('input').focus()
          this.$refs.textInput.$el.querySelector('input').blur()
        })
      }
    }
  }
}
</script>

<style lang="sass">
  .g-coord-picker
    width: 100%
    height: 300px
</style>
