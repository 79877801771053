import Vue from 'vue'
import { kebabCase } from 'vuetify/lib/util/helpers'

// load core components
const requireContext = require.context('./', true, /.*\.vue$/)
requireContext.keys().forEach((relativePath) => {
  const CamelCase = relativePath.split('/').reverse()[0].split('.')[0]
  const name = kebabCase(CamelCase)
  // name = name.startsWith('fire') ? `${name.substring(0, 1)}-${name.substring(1)}` : name
  Vue.component(name, requireContext(relativePath).default)
})
