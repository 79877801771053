<template>
  <div class="fire-draggable--container">
    <draggable
      :list="input"
      ghost-class="ghost"
      animation="200"
      :group="{ name: 'fields' }"
      direction="vertical"
      empty-insert-threshold="60"
      class="fire-draggable--draggable"
      handle=".draggable"
    >
      <transition-group type="transition">
        <v-card
          v-for="field in input"
          :key="field.id"
          flat
          outlined
        >
          <v-avatar
            class="draggable cursor-grab"
            :size="$vuetify.breakpoint.xsOnly ? 48 : 56"
          >
            <v-icon>mdi-drag-vertical</v-icon>
          </v-avatar>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                {{ field._type | fieldIcon }}
              </v-icon>
              <!-- <div class="caption grey--text">
                {{ field._type | fieldTitle }}
              </div> -->
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ field.title }}{{ field.required ? '*' : '' }}
              </v-list-item-title>
              <v-list-item-subtitle class="two-line">
                {{ field.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="flex-row">
              <v-btn
                icon
                :small="$vuetify.breakpoint.xsOnly"
                class="mx-1"
                :to="{ name: 'field', params: { fieldId: field.id } }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                :small="$vuetify.breakpoint.xsOnly"
                class="mx-1"
                @click="duplicateItem(field.id)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                icon
                :small="$vuetify.breakpoint.xsOnly"
                class="mx-1"
                @click="deleteItem(field.id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </transition-group>
    </draggable>
    <v-card-actions>
      <v-spacer />
      <v-btn
        outlined
        @click="$emit('click:add')"
      >
        Add Field
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { v1 as uuidv1 } from 'uuid'
export default {
  name: 'FireFieldsEditor',
  components: {
    Draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(list) {
        this.$emit('input', list)
      }
    }
  },
  methods: {
    duplicateItem(fieldId) {
      // TODO: Add unique field name check!
      if (confirm('Are you sure you want to duplicate this field?')) {
        const item = this.input.find(x => x.id === fieldId)
        const currentItemIndex = this.input.indexOf(item)
        if (!currentItemIndex === null) {
          // TOTEST: duplicate error
          return alert('error')
        }
        this.$emit('input', this.input.map((field) => {
          return field.id !== fieldId
            ? field
            : [
                field,
                {
                  ...field,
                  id: uuidv1(),
                  name: field.name + '_copy',
                  title: field.title + ' Copy'
                }
              ]
        }).flat())
      }
    },
    deleteItem(fieldId) {
      if (confirm('Are you sure you want to remove this field? Firestore data will not be deleted.')) {
        this.$emit('input', this.input.filter(x => x.id !== fieldId))
      }
    }
  }
}
</script>

<style lang="sass">
#fireful .v-application
  .fire-draggable--container
    position: relative
    .fire-draggable--draggable
      width: 100%
      min-height: 100px
      display: flex
      flex-direction: column
      padding-left: 0
      margin-bottom: 0
      > .v-card, > span > .v-card
        display: flex
        align-items: center
        margin-bottom: 1em
        > .v-list-item
          display: flex
          align-items: center
          padding-left: 0
          padding-right: 8px
          width: calc(100% - 56px)
          @media #{map-get($display-breakpoints, 'xs-only')}
            width: calc(100% - 48px)
          > .v-list-item__icon
            width: 24px
            overflow: hidden
            margin: 0 20px 0 0
            @media #{map-get($display-breakpoints, 'xs-only')}
              margin-right: 12px
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            align-self: center
          > .v-list-item__content
            padding: 0
            .v-list-item__title
              white-space: initial
              -webkit-line-clamp: 1
              -webkit-box-orient: vertical
              display: -webkit-box
    .fire-draggable--empty
      position: absolute
      opacity: 0.4
      top: 0
      left: 0
      right: 0
      bottom: 0
      border: 2px dashed #fd676b!important
  .ghost
    opacity: 0.5
    background: #c8ebfb
</style>
