<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <template #validations>
      <fire-card
        title="Allowed Extensions"
        description="Select from a list of accepted extensions. To support extensions outside this list please use the document field instead."
      >
        <v-row class="ma-0">
          <v-col
            v-for="(ext, i) in extensions"
            :key="i"
            cols="3"
          >
            <v-checkbox
              v-model="value.extensions"
              :label="ext"
              :value="ext"
              hide-details
            />
          </v-col>
        </v-row>
      </fire-card>
      <fire-number
        v-model="value.size"
        title="Max Size"
        description="The file size added to the field under validation must not exceed the specified size in kilobytes."
        integer
        disallow-negative
      />
    </template>
  </fire-field-form>
</template>

<script>
import { extensions } from './index.js'
import fields from '@/components/fields'
import { fieldFormsMixin } from '@/mixins'
export default {
  name: 'FireImageForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    extensions,
    field: fields.find(x => x.name === 'fire-image')
  })
}
</script>
