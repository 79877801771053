<template>
  <v-card
    v-show="!hidden && Object.keys(uploads).length"
    class="storage-uploader"
  >
    <v-toolbar dense dark flat>
      Uploading items
      <v-spacer />
      <v-btn
        icon
        text
        @click="collapsed = !collapsed"
      >
        <v-icon>
          mdi-chevron-{{ collapsed ? 'up' : 'down' }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        text
        @click="hide"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-actions v-if="uploadTask && uploadTask.state_ !== 'success'" class="justify-end">
      <v-btn
        v-if="uploadTask.state_ === 'running'"
        text
        x-small
        @click="uploadTask.pause()"
      >
        PAUSE
      </v-btn>
      <v-btn
        v-if="uploadTask.state_ === 'paused'"
        text
        x-small
        @click="uploadTask.resume()"
      >
        RESUME
      </v-btn>
      <v-btn
        text
        x-small
        @click="uploadTask.cancel()"
      >
        CANCEL
      </v-btn>
    </v-card-actions>
    <v-list
      v-show="!collapsed"
      class="scroll-list"
      dense
    >
      <v-list-item
        v-for="(item, id) in uploads"
        :key="id"
      >
        <v-list-item-avatar>
          <v-icon>
            mdi-file
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-list-item-title class="body-2" v-on="on">
                {{ item.file.name }}
              </v-list-item-title>
            </template>
            <span> {{ item.file.name }}</span>
          </v-tooltip>
          <v-list-item-subtitle>
            {{ item.status | capitalizeFirstLetter }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            v-if="item.status === 'complete'"
            icon
            text
          >
            <v-icon color="success">
              mdi-check-circle
            </v-icon>
          </v-btn>
          <v-btn
            v-else-if="item.status === 'running'"
            icon
            text
          >
            <v-progress-circular
              :value="item.progress"
              size="22"
              width="2"
            />
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'StorageUploader',
  data: () => ({
    filenameExistsCounter: 0,
    uploadTask: null,
    collapsed: false
  }),
  computed: {
    ...mapState('firebase', [
      'storage',
      'user'
    ]),
    ...mapState('uploader', {
      uploads: state => state.uploads,
      fileIndex: state => state.fileIndex,
      hidden: state => state.hidden
    }),
    currentUploadId() {
      if (this.fileIndex >= Object.keys(this.uploads).length) {
        return null
      }
      return Object.values(this.uploads)[this.fileIndex].id
    },
    currentUpload: {
      get() {
        return this.uploads[this.currentUploadId]
      },
      set(value) {
        this.$set(this.uploads, this.currentUploadId, value)
      }
    },
    currentFile() {
      return this.currentUpload ? this.currentUpload.file : null
    },
    currentUploadRef() {
      if (!this.currentFile) {
        return null
      }
      let filename = this.currentFile.name
      if (this.filenameExistsCounter > 0) {
        const splitFilename = filename.split('.')
        const ext = splitFilename.splice(-1, 1)[0]
        filename = splitFilename.join('.')
        filename = `${filename} (${this.filenameExistsCounter}).${ext}`
      }
      return this.storage.ref().child(`${this.currentUpload.path}/${filename}`)
    }
  },
  watch: {
    currentUploadId: {
      async handler(id) {
        if (id && this.currentUploadRef) {
          this.filenameExistsCounter = 0
          !this.user && await this.getAsyncCurrentUser()
          this.currentUploadRef && this.currentUploadRef.getDownloadURL().then(this.onResolve, this.onReject)
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('uploader', [
      'uploadNext',
      'hide'
    ]),
    ...mapActions('firebase', [
      'getAsyncCurrentUser'
    ]),
    onResolve(foundURL) {
      this.filenameExistsCounter++
      this.currentUploadRef.getDownloadURL().then(this.onResolve, this.onReject)
    },
    onReject(error) {
      if (error.code === 'storage/object-not-found') {
        this.upload()
      }
    },
    upload() {
      if (!this.currentFile) {
        this.$Sentry.captureMessage('No file selected, yet upload was triggered')
        return
      }
      this.uploadTask = this.currentUploadRef.put(this.currentFile)
      this.uploadTask.on(
        'state_changed',
        this.uploadStateChanged,
        this.uploadFailed,
        this.uploadComplete
      )
    },
    uploadStateChanged(snapshot) {
      const progress = parseInt((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
      this.currentUpload.progress = progress
      if (snapshot.state) {
        this.currentUpload.status = snapshot.state
      }
    },
    uploadFailed(error) {
      this.currentUpload.status = this.uploadTask.state_ || 'error'
      this.uploadNext()
      this.$store.dispatch('snackbar/new', { error })
      this.$Sentry.captureException(error)
    },
    uploadComplete() {
      this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.currentUpload.status = 'complete'
        this.uploadNext()
      })
    }
  }
}
</script>

<style lang="sass">
  .v-card.storage-uploader
    position: fixed
    bottom: 10px
    width: 100%
    z-index: 999999
    @media #{map-get($display-breakpoints, 'sm-and-up')}
      right: 10px
      max-width: 400px
    .v-list
      padding: 0
    .v-list--dense .v-list-item .v-list-item__content
      padding: 6px 0
    .v-list-item__action, .v-list-item__avatar, .v-list-item__icon
      margin: 6px 0
    .v-list-item__avatar:first-child
      margin-right: 16px
    .scroll-list
      max-height: 300px
      overflow-y: scroll
</style>
