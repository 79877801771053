export const unpackDownloadURL = function(value) {
  if (!value) {
    return {}
  }
  const re = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/(.*)\/o\/(.*)(?:\?alt=media)(?:[&|?]token=(.*))?/
  const match = decodeURIComponent(value).match(re)
  if (!match) {
    return {}
  }
  const [, bucket, fullPath, token] = match
  const filename = fullPath.split('/').reverse()[0]
  const ext = filename.split('.')[1]
  return { bucket, fullPath, filename, ext, token }
}

export const nestedSearch = (value, query) => {
  if (Array.isArray(value)) {
    return value.map(v => nestedSearch(v, query)).includes(true)
  } else if (value && typeof value === 'object') {
    return Object.keys(value).map(v => nestedSearch(value[v], query)).includes(true)
  }
  return String(value).includes(query)
}

export const nestedFind = (value, fn) => {
  if (Array.isArray(value)) {
    return value.map(v => nestedFind(v, fn))
  } else if (value && typeof value === 'object') {
    return Object.keys(value).map(v => nestedFind(value[v], fn))
  }
  return fn(value)
}

export const nestedStorageReferencesSearch = (doc) => {
  return nestedFind(doc, (value) => {
    if (value && typeof value === 'string' && value.startsWith && value.startsWith('https://firebasestorage.googleapis.com/v0/b/')) {
      const { fullPath } = unpackDownloadURL(value)
      return fullPath || false
    }
    return false
  }).flat().filter(x => !!x)
}

export const wait = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}
