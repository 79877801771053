<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label"
    tag="div"
    class="fire-input"
  >
    <v-select
      v-model="input"
      :items="items"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      outlined
      :required="required"
      :item-value="itemValue"
      :item-text="itemTitle || itemValue"
      :error-messages="errors"
      :clearable="clearable"
      :disabled="disabled"
      class="v-input__append-inner--centered"
    >
      <template
        v-if="$scopedSlots.selection || (itemIcon || itemImage || itemSubtitle)"
        #selection="{ item }"
      >
        <slot name="selection" :item="item">
          <v-list-item v-if="itemIcon || itemImage || itemSubtitle" dense style="width: 100%;">
            <v-list-item-avatar v-if="itemIcon || itemImage">
              <v-icon v-if="itemIcon" v-text="item[itemIcon]" />
              <v-img v-else :src="item[itemImage]" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ itemTitle ? item[itemTitle] : item[itemValue] }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="itemSubtitle"
                style="max-width: 200px;"
              >
                {{ item[itemSubtitle] }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </slot>
      </template>
      <template
        v-if="$scopedSlots.item || (itemIcon || itemImage || itemSubtitle)"
        #item="{ item }"
      >
        <slot name="item" :item="item">
          <template v-if="itemIcon || itemImage || itemSubtitle">
            <v-list-item-avatar v-if="itemIcon || itemImage">
              <v-icon v-if="itemIcon" v-text="item[itemIcon]" />
              <v-img v-else :src="item[itemImage]" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ itemTitle ? item[itemTitle] : item[itemValue] }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="itemSubtitle"
                style="max-width: 200px;"
              >
                {{ item[itemSubtitle] }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </slot>
      </template>
    </v-select>
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireSelect',
  description: 'A select field for selecting a single item from a list of options.',
  // icon: 'mdi-format-list-bulleted',
  icon: 'mdi-form-select',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => null
    },
    name: {
      type: String,
      default: () => 'select'
    },
    title: {
      type: String,
      default: () => 'Select'
    },
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: () => 'value'
    },
    itemTitle: {
      type: String,
      default: () => null
    },
    itemIcon: {
      type: String,
      default: () => null
    },
    itemImage: {
      type: String,
      default: () => null
    },
    itemSubtitle: {
      type: String,
      default: () => null
    },
    clearable: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="sass">
  .fire-input
    .v-text-field .v-input__prepend-inner, .v-text-field .v-input__append-inner
      align-self: center
      margin-top: 0
</style>
