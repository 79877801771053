<template>
  <v-dialog
    v-model="dialog"
    content-class="fireful-right-dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :width="$vuetify.breakpoint.smAndDown ? null : width"
    hide-overlay
    scrollable
    transition="slide-x-reverse-transition"
  >
    <slot />
  </v-dialog>
</template>

<script>
export default {
  name: 'DrawerDialog',
  props: {
    width: {
      type: [Number, String],
      default: () => 600
    }
  },
  computed: {
    dialog: {
      get() {
        return true
      },
      set(dialogState) {
        if (!dialogState) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .fireful-right-dialog
    margin: 0
    margin-left: auto
    border-radius: 0
    &.v-dialog:not(.v-dialog--fullscreen)
      height: 100vh
      max-height: 100vh
</style>
