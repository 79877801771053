<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <v-switch
      v-model="input"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      hide-details="auto"
      inset
      color="primary"
      :class="`ma-2 mb-4${allowIndeterminate && (value === null || value === undefined) ? ' indeterminate' : '' }`"
      :error="errors.length > 0"
      :error-messages="errors"
      :disabled="disabled"
    />
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireSwitch',
  description: 'A boolean switch field that can either be true or false.',
  icon: 'mdi-toggle-switch',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: Boolean,
      default: () => null
    },
    name: {
      type: String,
      default: () => 'switch'
    },
    title: {
      type: String,
      default: () => 'Switch'
    },
    allowIndeterminate: {
      type: Boolean,
      default: () => false
    },
    requiredTrue: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    /**
     * Overwrite global fields input mixin to allow for indeterminate state
     */
    input: {
      get() {
        return this.value
      },
      set(value) {
        if (this.allowIndeterminate && this.value === false && value === true) {
          return this.$emit('input', null)
        }
        this.$emit('input', value)
      }
    },
    additionalRules() {
      const rules = {}
      if (this.requiredTrue) {
        rules.required = { allowFalse: false }
      }
      return rules
    }
  }
}
</script>

<style lang="sass">
  .fire-input .v-input--switch.indeterminate
    .v-input--selection-controls__ripple
      left: -3px
    .v-input--switch__thumb
      left: 11px
      position: relative
      &:before
        position: absolute
        font-family: "Material Design Icons"
        font-size: 1.5rem
        content: "\F0377"
        color: rgba(0, 0, 0, 0.2)
</style>
