<template>
  <fire-field-form
    v-model="value"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  />
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireDocumentForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-document')
  })
}
</script>
