<template>
  <v-list-item
    :to="to"
    color="primary"
    exact
  >
    <v-list-item-avatar v-if="itemAvatar" tile>
      <v-icon v-if="(fields.find(x => x.name === itemAvatar) || {})._type === 'fire-icon'">
        mdi-{{ item[itemAvatar] }}
      </v-icon>
      <v-img v-else :src="item[itemAvatar] | fireImage({ w: 40, h: 40 })" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <template v-if="!itemTitle">
          <span>{{ item.id }}</span>
        </template>
        <template v-else-if="item[itemTitle]">
          {{ item[itemTitle] | displayPreviewString }}
        </template>
        <template v-else>
          -
        </template>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ item[itemSubtitle] | displayPreviewString }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <slot name="action" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'FireDocumentListItem',
  filters: {
    displayPreviewString(value) {
      if (value && typeof value.toDate === 'function') {
        return value.toDate().toLocaleString()
      }
      return value
    }
  },
  props: {
    to: {
      type: [Object, String],
      default: () => null
    },
    fields: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => null
    },
    itemTitle: {
      type: String,
      default: () => null
    },
    itemSubtitle: {
      type: String,
      default: () => null
    },
    itemAvatar: {
      type: String,
      default: () => null
    }
  }
}
</script>
