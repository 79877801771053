import Vue from 'vue'
import fields from './../components/fields'

Vue.filter('fieldIcon', (value) => {
  const field = fields.find(x => x.name === value) || {}
  return field.icon
})
Vue.filter('fieldTitle', (value) => {
  const field = fields.find(x => x.name === value) || {}
  return field.title
})
Vue.filter('fieldDescription', (value) => {
  const field = fields.find(x => x.name === value) || {}
  return field.description
})

Vue.filter('fieldFormComponent', (value) => {
  const field = fields.find(x => x.name === value) || {}
  return field.formComponentName
})

Vue.filter('fieldSkeleton', (value) => {
  const field = fields.find(x => x.name === value) || {}
  return field.skeleton || 'list-item'
})
