<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <template #validations>
      <fire-switch
        v-model="value.integer"
        title="Only integers"
      />
      <fire-switch
        v-model="value.disallowNegative"
        title="Disallow negative numbers"
      />
      <fire-number
        v-model="value.minValue"
        title="Min Value"
        description="Enter a value to constrain the input to a minimum numeric value."
        :integer="value.integer === true"
        :disallow-negative="value.disallowNegative === true"
        :max-value="value.maxValue"
      />
      <fire-number
        v-model="value.maxValue"
        title="Max Value"
        description="Enter a value to constrain the input to a maximum numeric value."
        :integer="value.integer === true"
        :disallow-negative="value.disallowNegative === true"
        :min-value="value.minValue"
      />
    </template>
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireNumberForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-number')
  })
}
</script>
