<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <v-textarea
      v-model="input"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      hide-details="auto"
      outlined
      :auto-grow="autoGrow"
      :rows="rows"
      :error-messages="errors"
      :counter="counter ? maxLength || true : false"
      :no-resize="noResize"
      :disabled="disabled"
    >
      <template v-if="$scopedSlots['append']" #append>
        <slot name="append" />
      </template>
    </v-textarea>
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireText',
  description: 'A multi-line textarea field.',
  icon: 'mdi-card-text-outline',
  skeleton: 'list-item-three-line',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => 'text'
    },
    title: {
      type: String,
      default: () => 'Text'
    },
    placeholder: {
      type: String,
      default: () => null
    },
    rows: {
      type: [String, Number],
      default: () => '5'
    },
    autoGrow: {
      type: Boolean,
      default: () => true
    },
    noResize: {
      type: Boolean,
      default: () => false
    },
    counter: {
      type: Boolean,
      default: () => false
    },
    minLength: {
      type: [Number, String],
      default: () => null
    },
    maxLength: {
      type: [Number, String],
      default: () => null
    },
    useCustomRules: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    additionalRules() {
      const rules = {}
      const minLength = parseInt(this.minLength)
      const maxLength = parseInt(this.maxLength)
      if (!isNaN(minLength)) {
        rules.min = minLength
      }
      if (!isNaN(maxLength)) {
        rules.max = maxLength
      }
      return rules
    }
  }
}
</script>
