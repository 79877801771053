import store from '@/store'

export default (to, from, next) => {
  const user = store.state.user
  if (!user && to.name !== 'login') {
    next({ name: 'login', query: from.path !== '/' ? { redirect: from.fullPath } : {} })
    return
  }
  if (user && to.name === 'login') {
    next({ name: 'index' })
    return
  }
  next()
}
