import Vue from 'vue'
import store from '@/store'
import './fields'
import './images'
import './dates'

const jsonView = (value, fieldName) => {
  let jsonObject = {}
  if (fieldName) {
    jsonObject[fieldName] = value
  } else {
    if (value === undefined) {
      value = 'undefined'
    }
    if (value === null) {
      value = 'null'
    }
    if (typeof value === 'string') {
      return value
    }
    jsonObject = value
  }
  const jsonValue = {}
  Object.keys(jsonObject).forEach((field) => {
    let fieldValue = jsonObject[field]
    if (fieldValue && fieldValue.constructor) {
      if (['Date', 'RegExp'].includes(fieldValue.constructor.name)) {
        fieldValue = fieldValue.toString()
      }
      if (fieldValue.constructor.name === 't' && 'path' in fieldValue) {
        fieldValue = fieldValue.path
      }
    }
    if (fieldValue !== undefined) {
      jsonValue[field] = fieldValue
    }
  })
  return jsonValue
}
Vue.filter('jsonView', jsonView)

Vue.filter('docKey', (doc) => {
  const date = doc._updatedAt || doc._createdAt
  if (date) {
    return date.toDate().toJSON()
  }
  return doc.id
})

export const getPreview = (of) => {
  if (!of) {
    return {}
  }
  const documentType = of.to || of.name
  return (store.getters.collections[documentType] || {}).preview
}

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter)

const formatPrice = (value) => {
  return value ? parseFloat(value / 100) : ''
}
Vue.filter('formatPrice', formatPrice)

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
}
Vue.filter('formatBytes', formatBytes)

const nestedSearch = (value, query) => {
  if (value.constructor.name === 'DocumentReference') {
    return false
  }
  if (Array.isArray(value)) {
    return value.map(v => nestedSearch(v, query)).includes(true)
  } else if (value && typeof value === 'object') {
    return Object.keys(value).map(v => nestedSearch(value[v], query)).includes(true)
  }
  return String(value).includes(query)
}

Vue.filter('initials', (value, quantity) => {
  quantity = quantity || 2
  const words = value.split(' ').map(w => w[0])
  if (quantity) {
    words.splice(quantity)
  }
  return words.join('')
})

Vue.mixin({
  methods: {
    capitalizeFirstLetter,
    getPreview,
    nestedSearch
  }
})
