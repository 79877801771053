<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-array
      v-model="value.items"
      title="Item"
      description="Configure a list of items for the user to choose from."
      item-subtitle="value"
      item-title="label"
    >
      <template #[`item.edit`]="{ item }">
        <fire-string
          v-model="item.value"
          title="Value"
          description="The value that will be saved in firebase."
          required
        />
        <fire-string
          v-model="item.label"
          title="Label"
          description="The radio label text that will be displayed to the user."
        />
      </template>
    </fire-array>
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireRadioForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-radio')
  })
}
</script>
