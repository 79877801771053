export default {
  props: {
    hideFieldName: {
      type: Boolean,
      default: () => false
    },
    forceRequired: {
      type: Boolean,
      default: () => false
    },
    excludeFieldNames: {
      type: Array,
      default: () => []
    }
  }
}
