<template>
  <div>
    <v-alert
      type="info"
      outlined
    >
      The beta version of fireful only supports material design icons. To see a full list of the icons and how to include them in your project please refer to the material design icons getting started page <a href="https://dev.materialdesignicons.com/getting-started">here</a>.
    </v-alert>
    <fire-field-form
      v-model="value"
      :hide-field-name="hideFieldName"
      :exclude-field-names="excludeFieldNames"
    />
  </div>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireIconForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-icon')
  })
}
</script>
