<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <v-radio-group
      v-model="input"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      :error-messages="errors"
      :error="errors.length > 0"
      :mandatory="mandatory"
      :readonly="disabled"
      :row="row"
    >
      <v-radio
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </v-radio-group>
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireRadio',
  description: 'A radio choice field.',
  icon: 'mdi-radiobox-marked',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => null
    },
    name: {
      type: String,
      default: () => 'radio'
    },
    title: {
      type: String,
      default: () => 'Radio'
    },
    mandatory: {
      type: Boolean,
      default: () => false
    },
    row: {
      type: Boolean,
      default: () => false
    },
    items: {
      type: Array,
      default: () => ([
        {
          value: 'a',
          label: 'Example Choice A'
        },
        {
          value: 'b',
          label: 'Example Choice B'
        }
      ])
    }
  },
  beforeMount() {
    if (!this.value && !this.defaultValue) {
      const defaultValue = this.items.find(x => x.default)?.value
      if (defaultValue) {
        this.input = defaultValue
      }
    }
  }
}
</script>

<style lang="sass">
.v-input--radio-group--column .v-input--radio-group__input > .v-label
  margin-bottom: 8px
</style>
