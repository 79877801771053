import Vue from 'vue'

Vue.filter('getUserRoleById', (value, userId) => {
  if (!value) {
    return undefined
  }
  if (value.createdBy === userId) {
    return 'owner'
  }
  return value.roles ? value.roles[userId] : undefined
})
