<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <v-text-field
      v-model="input"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      :hide-details="hideDetails"
      outlined
      :placeholder="placeholder"
      :error-messages="errors"
      :counter="counter ? maxLength || true : false"
      :disabled="disabled"
      :clearable="clearable"
      :dense="dense"
      :autofocus="autofocus"
    />
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireString',
  description: 'A single-line string field.',
  icon: 'mdi-form-textbox',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => 'string'
    },
    title: {
      type: String,
      default: () => 'String'
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    dense: {
      type: Boolean,
      default: () => false
    },
    hideDetails: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    unique: {
      type: Boolean,
      default: () => null
    },
    collectionName: {
      type: String,
      default: () => null
    },
    documentId: {
      type: String,
      default: () => null
    },
    counter: {
      type: Boolean,
      default: () => false
    },
    minLength: {
      type: [Number, String],
      default: () => null
    },
    maxLength: {
      type: [Number, String],
      default: () => null
    },
    format: {
      type: String,
      default: () => null
    },
    regex: {
      type: RegExp,
      default: () => null
    },
    useCustomRules: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    additionalRules() {
      const rules = {}
      const minLength = parseInt(this.minLength)
      const maxLength = parseInt(this.maxLength)
      if (!isNaN(minLength)) {
        rules.min = minLength
      }
      if (!isNaN(maxLength)) {
        rules.max = maxLength
      }
      if (this.format) {
        rules[this.format] = true
      }
      if (this.regex) {
        rules.regex = this.regex
      }
      if (this.unique && this.collectionName && this.documentId) {
        rules.push(`unique:${this.collectionName},${this.documentId}`)
      }
      return rules
    }
  }
  // methods: {
  //   strToRegex(str) {
  //     // https://stackoverflow.com/questions/874709/converting-user-input-string-to-regular-expression
  //     const match = str.match(new RegExp('^/(.*?)/([gimy]*)$'))
  //     return new RegExp(match[1], match[2])
  //   }
  // }
}
</script>
