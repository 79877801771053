<template>
  <div>
    <v-snackbar
      v-for="(item, i) in items"
      :key="item.id"
      bottom
      :timeout="item.timeout === undefined ? 3000 : item.timeout"
      :value="true"
      :color="item.type || 'primary'"
      :style="{ 'margin-bottom': `${(items.length - (i + 1)) * 60}px` }"
      @input="!$event && dismiss(item.id)"
    >
      {{ item.message }}
      <template #action>
        <v-btn
          dark
          icon
          @click="dismiss(item.id)"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('snackbar')
export default {
  name: 'Snackbars',
  computed: {
    ...mapState(['items'])
  },
  methods: {
    ...mapActions(['dismiss'])
  }
}
</script>
