<template>
  <v-app>
    <v-card height="100%">
      <v-toolbar
        v-if="$vuetify.breakpoint.xsOnly"
        color="primary"
      >
        <v-tabs
          v-model="tab"
          dark
          grow
        >
          <v-tab>Configure</v-tab>
          <v-tab>Preview</v-tab>
        </v-tabs>
        <v-btn
          icon
          dark
          @click="fullscreen = !fullscreen"
        >
          <v-icon>
            mdi-{{ fullscreen ? 'close' : 'fullscreen' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <div class="fire-column--container v-dialog--scrollable">
        <v-card
          flat
          class="fire-column"
          :class="{ 'd-none': $vuetify.breakpoint.xsOnly && tab === 1 }"
        >
          <v-card-title v-show="!$vuetify.breakpoint.xsOnly">
            Field Configurator
          </v-card-title>
          <v-card-subtitle class="pt-8 pt-sm-2 ml-1">
            Browse the fields and their configuration options.
          </v-card-subtitle>
          <v-divider />
          <v-card-text>
            <fire-select
              v-model="mockField._type"
              :items="fields"
              item-value="name"
              item-title="title"
              item-icon="icon"
              item-subtitle="description"
              title="Field Type"
            />
            <component
              :is="mockField._type | fieldFormComponent"
              v-model="mockField"
            />
          </v-card-text>
        </v-card>
        <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical />
        <v-card
          flat
          class="fire-column"
          :class="{ 'd-none': $vuetify.breakpoint.xsOnly && tab === 0 }"
        >
          <v-card-title v-show="!$vuetify.breakpoint.xsOnly">
            Field Preview
          </v-card-title>
          <v-card-subtitle class="pt-8 pt-sm-2 pl-5">
            This is what the field will look like for your content managers.
          </v-card-subtitle>
          <v-divider />
          <v-card-text>
            <v-alert
              v-if="!mockField._type"
              type="info"
              outlined
            >
              Please choose a field type to see its preview.
            </v-alert>
            <template v-else>
              <validation-observer v-slot="{ validate }">
                <component
                  :is="mockField._type"
                  v-bind="mockField"
                  v-model="mockValue"
                />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="secondary" outlined @click="validate">
                    Validate
                  </v-btn>
                </v-card-actions>
              </validation-observer>
              <v-divider />
              <v-card-title class="px-0">
                Field Value Preview
              </v-card-title>
              <v-card-subtitle class="px-0">
                This is how the field will be saved on firestore.
              </v-card-subtitle>
              <json-viewer
                :value="mockValue | jsonView(mockField.name)"
                :expand-depth="5"
                copyable
              />
            </template>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import fields from '@/components/fields'
export default {
  name: 'Playground',
  data: () => ({
    tab: null,
    fullscreen: false,
    fields: fields.filter(x => x !== 'fire-reference'),
    mockField: {},
    mockValue: null
  }),
  watch: {
    'mockField._type'(_type) {
      const newField = this.fields.find(x => x.name === _type)
      this.mockValue = newField.defaultValue
      this.mockField = { _type, ...newField.defaults }
    },
    fullscreen(fullscreen) {
      window.parent.postMessage({ key: 'fullscreen', value: fullscreen, channel: 'fireful-playground' }, '*')
    }
  },
  mounted() {
    const html = document.getElementsByTagName('html')[0]
    html.className = html.className.replace(/\bloading\b/, 'fireful-playground')
  }
}
</script>

<style lang="sass">
html#fireful.fireful-playground
  overflow-y: hidden
  .fire-column--container
    @media #{map-get($display-breakpoints, 'sm-and-up')}
      height: 100vh
    .fire-column:not(:last-child)
      max-width: 380px
      flex: 1 1 100%
</style>
