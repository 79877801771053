<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      ref="input"
      :rules="rules"
      :name="label.toLowerCase()"
      tag="div"
      class="fire-input"
    >
      <fire-card
        :title="pluralLabel"
        :description="description"
        :disabled="disabled"
        :error-messages="errors"
      >
        <grid-array
          v-model="input"
          :item-photo="itemPhoto"
          :item-icon="itemIcon"
          :item-title="itemTitle"
          :item-subtitle="itemSubtitle"
          @edit-item="edit($event)"
        >
          <template v-if="$scopedSlots['item.icon']" #[`item.icon`]="{ item }">
            <slot name="item.icon" :item="item" />
          </template>
          <template v-if="$scopedSlots['item.title']" #[`item.title`]="{ item }">
            <slot name="item.title" :item="item" />
          </template>
          <template v-else-if="ofField && ofField.preview" #[`item.title`]="{ item }">
            {{ ofField.preview(item) }}
          </template>
          <template v-if="$scopedSlots['item.subtitle']" #[`item.subtitle`]="{ item }">
            <slot name="item.subtitle" :item="item" />
          </template>
        </grid-array>
        <slot v-if="!input.length" name="empty">
          <template v-if="emptyMessage">
            {{ emptyMessage }}
          </template>
          <template v-else-if="pluralLabel">
            No {{ pluralLabel.toLowerCase() }} have been added so far.
          </template>
        </slot>
        <template #actions>
          <v-spacer />
          <v-btn
            outlined
            @click="add"
          >
            Add {{ title }}
          </v-btn>
        </template>
      </fire-card>
    </validation-provider>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="900"
      :fullscreen="fullscreen"
    >
      <validation-observer
        v-if="dialog"
        v-slot="{ invalid }"
        ref="form"
        tag="form"
        @submit.prevent="save"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
            <v-spacer />
            <v-btn
              icon
              @click="fullscreen = !fullscreen"
            >
              <v-icon>
                mdi-fullscreen
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pt-4">
            <!-- if a slot is passed use that, otherwise use the of value -->
            <slot name="item.edit" :item="editItem">
              <component
                :is="of._type"
                v-bind="of"
                v-if="of._type"
                v-model="editItem"
              />
            </slot>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              type="submit"
              :disabled="invalid"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { clone } from 'lodash-es'
import { fieldsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireArray',
  description: 'Create an array of any type.',
  icon: 'mdi-code-brackets',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: () => 'array'
    },
    title: {
      type: String,
      default: () => 'Array'
    },
    pluralTitle: {
      type: String,
      default: () => ''
    },
    of: {
      type: [Object, Array],
      default: () => ({})
    },
    itemIcon: {
      type: String,
      default: () => null
    },
    itemPhoto: {
      type: String,
      default: () => null
    },
    itemTitle: {
      type: String,
      default: () => null
    },
    itemSubtitle: {
      type: String,
      default: () => null
    },
    emptyMessage: {
      type: String,
      default: () => ''
    },
    minItems: {
      type: [Number, String],
      default: () => null
    },
    maxItems: {
      type: [Number, String],
      default: () => null
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      fullscreen: false,
      editIndex: -1,
      editItem: {}
    }
  },
  computed: {
    formTitle() {
      return `${this.editIndex === -1 ? 'New' : 'Edit'} ${this.title}`
    },
    pluralLabel() {
      if (!this.title) {
        return ''
      }
      const title = this.pluralTitle || `${this.title}s`
      return `${title}${this.required ? '*' : ''}`
    },
    additionalRules() {
      const arrLength = {}
      const minItems = parseInt(this.minItems)
      const maxItems = parseInt(this.maxItems)
      if (!isNaN(minItems)) {
        arrLength.min = minItems
      }
      if (!isNaN(maxItems)) {
        arrLength.max = maxItems
      }
      if (arrLength.min || arrLength.max) {
        return { arrLength }
      }
      return {}
    },
    ofField() {
      return fields.find(x => x.name === this.of._type)
    }
  },
  watch: {
    input() {
      this.$nextTick(() => {
        this.$refs.input.validate()
      })
    },
    dialog(dialog) {
      !dialog && this.$nextTick(() => {
        this.$refs.input.validate()
      })
    }
  },
  methods: {
    add() {
      this.editIndex = -1
      // get the default value of the type of item this has an of type
      this.editItem = this.ofField ? clone(this.ofField.defaultValue) : {}
      this.dialog = true
    },
    edit(item) {
      this.editIndex = this.value.indexOf(item)
      this.editItem = clone(item)
      this.dialog = true
    },
    async save() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }
      this.dialog = false
      if (this.editIndex > -1) {
        this.$set(this.input, this.editIndex, clone(this.editItem))
        return
      }
      this.input = [...this.value, this.editItem]
    }
  }
}
</script>
