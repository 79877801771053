export default [
  // MAIN LAYOUT
  {
    path: '/',
    component: () => import('@panel/layouts/base.vue'),
    children: [
      { path: '', name: 'index', redirect: 'projects' },
      {
        path: 'projects',
        name: 'projects',
        component: () => import('@panel/pages/projects/index.vue'),
        children: [
          {
            path: 'new',
            name: 'new-project',
            component: () => import('@panel/pages/projects/new.vue')
          }
        ]
      },
      {
        path: 'projects/:id',
        component: () => import('@panel/layouts/project.vue'),
        children: [
          {
            path: '',
            name: 'project-index',
            redirect: 'studio'
          },
          {
            path: 'studio',
            component: () => import('@panel/pages/projects/project/studio.vue'),
            children: [
              {
                path: '',
                name: 'project-studio',
                component: () => import('@/components/studio/collections.vue'),
                children: [
                  {
                    path: ':collectionRef',
                    name: 'documents',
                    component: () => import('@/components/studio/documents.vue'),
                    children: [
                      {
                        path: ':documentId',
                        name: 'document',
                        component: () => import('@/components/studio/document.vue')
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'collections',
            name: 'collections',
            component: () => import('@panel/pages/projects/project/collections/index.vue'),
            children: [
              {
                path: 'new',
                name: 'new-collection',
                component: () => import('@panel/pages/projects/project/collections/new.vue')
              }
            ]
          },
          {
            path: 'collections/:collectionId',
            name: 'collection',
            component: () => import('@panel/pages/projects/project/collections/collection/index.vue'),
            children: [
              {
                path: ':fieldId',
                name: 'field', // if you change the name field make sure to update the scrollbehavior
                component: () => import('@panel/pages/projects/project/collections/collection/field.vue')
              }
            ]
          },
          { path: 'storage', name: 'project-storage', component: () => import('@panel/pages/projects/project/storage.vue') },
          // { path: 'team', name: 'project-team', component: () => import('@panel/pages/projects/project/team.vue') },
          { path: 'settings', name: 'project-settings', component: () => import('@panel/pages/projects/project/settings.vue') }
          // { path: 'plan', name: 'plan', component: () => import('@panel/pages/_id/plan.vue') },
        ]
      }
    ]
  },
  // CLEAN LAYOUT
  {
    path: '/',
    component: () => import('@panel/layouts/clean.vue'),
    children: [
      { meta: { title: 'Login To Your Panel' }, path: '/login', name: 'login', component: () => import('@panel/pages/login.vue') },
      { path: '/404', name: '404', component: () => import('@/pages/Code404.vue') }
      // { path: '/403', name: '403', component: () => import('@panel/pages/error/Code403.vue') },
    ]
  },
  { path: '*', redirect: { name: '404' } }
]
