<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
    required-description="Removes the indeterminate null state. Requires the field to be set to false or true."
  >
    <fire-switch
      v-model="value.allowIndeterminate"
      title="Allow Indeterminate"
      description="Allows a third intederminate (null) state to be set."
    />
    <template #validations>
      <fire-switch
        v-model="value.requiredTrue"
        title="Required True"
        description="Requires the field to be in the true state."
      />
    </template>
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireSwitchForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-switch')
  })
}
</script>
