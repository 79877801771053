<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
    :force-required="forceRequired"
  >
    <fire-switch
      v-model="value.allowHexa"
      title="Allow hexa"
    />
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireColorForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-color')
  })
}
</script>
