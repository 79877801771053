import '@/plugins/sentry'
// Determine app to start from hostname
const getApp = function() {
  const { hostname } = window.location
  if (process.env.NODE_ENV === 'development') {
    if (hostname === 'dev.studio.fireful.io') {
      return 'studio'
    }
    if (hostname === 'dev.panel.fireful.io') {
      return 'panel'
    }
    if (hostname === 'dev.playground.fireful.io') {
      return 'playground'
    }
    throw new Error('Could not get app from hostname')
  }
  if (hostname === 'panel.fireful.io') {
    return 'panel'
  }
  if (hostname === 'playground.fireful.io') {
    return 'playground'
  }
  if (hostname.endsWith('fireful.studio')) {
    return 'studio'
  }
  throw new Error('Could not get app from hostname')
}

const appName = getApp()
require(`./${appName}/main.js`)
