<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="isFullscreen"
    max-width="720px"
    scrollable
  >
    <v-card
      flat
      min-height="80vh"
    >
      <v-card-title>
        Select {{ assetType | capitalizeFirstLetter }}
        <v-spacer />
        <v-btn
          v-if="!$vuetify.breakpoint.xsOnly"
          fab
          text
          class="mt-n2 mr-n2"
          @click="fullscreen = !fullscreen"
        >
          <v-icon>mdi-fullscreen</v-icon>
        </v-btn>
        <v-btn
          fab
          text
          class="mt-n2 mr-n4"
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text flex-wrap>
        <storage-library
          :asset-type="assetType"
          :home-directory="directory"
          @select="$emit('input', $event)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'StorageBrowser',
  props: {
    value: {
      type: [Boolean, Object],
      default: () => false
    },
    assetType: {
      type: String,
      default: () => 'image'
    },
    directory: {
      type: String,
      default: () => ''
    },
    extensions: {
      type: Array,
      default: () => []
    },
    size: {
      type: Number,
      default: () => null
    }
  },
  data: () => ({
    dialog: false,
    fullscreen: false,
    currentView: ''
  }),
  computed: {
    isFullscreen() {
      return this.$vuetify.breakpoint.xsOnly || this.fullscreen
    }
  },
  watch: {
    value: {
      handler() {
        this.dialog = this.value !== false
      },
      immediate: true
    },
    dialog: {
      handler(val) {
        !val && this.$emit('input', false)
      },
      immediate: true
    }
  }
}
</script>
