<template>
  <validation-observer
    ref="form"
    v-slot="{ valid }"
    tag="form"
    @submit.prevent="save"
  >
    <v-card-text
      class="mb-0 pt-3"
    >
      <fire-string
        v-model="form.title"
        title="Fieldset Title"
        description="Enter a singular title for the items in this fieldset."
        required
        unique
      />
      <fire-string
        v-model="form.plural"
        title="Plural Title"
        :placeholder="form.title ? `${form.title}s` : ''"
        :description="form.title ? `Enter if you would like to use something other than '${form.title}s'` : ''"
      />
      <fire-array
        :key="form.id"
        v-model="form.fields"
        title="Fields"
        plural-title="Fields"
        item-title="title"
        item-subtitle="_type"
        min-items="2"
        required
      >
        <template #empty>
          No fields have been configured so far.
        </template>
        <template #[`item.edit`]="{ item }">
          <field-editor :value="item" />
        </template>
        <template #[`item.icon`]="{ item }">
          <v-icon>
            {{ item._type | fieldIcon }}
          </v-icon>
        </template>
      </fire-array>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        type="submit"
        text
        :loading="saving"
        :disabled="!valid || saving"
      >
        Save
      </v-btn>
    </v-card-actions>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import { db, Timestamp } from './../plugins/firebase'
export default {
  name: 'FieldsetEditor',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    form: {},
    saving: false,
    mockFormData: {}
  }),
  computed: {
    ...mapGetters(['project'])
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.form = JSON.parse(JSON.stringify({ id: value.id, ...value }))
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async save() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      const changedKeys = Object.keys(this.form).filter((key) => {
        return !['updatedAt', 'createdAt'].includes(key) && !isEqual(this.form[key], this.value[key])
      })
      if (!changedKeys.length) {
        return this.$store.dispatch('snacbar/new', { type: 'error', message: 'Nothing to save.' })
      }
      const changedFields = {}
      changedKeys.forEach((key) => {
        changedFields[key] = this.form[key]
      })
      this.saving = true
      try {
        let { id } = this.form
        if (id) {
          await db.collection(`projects/${this.project.id}/fieldsets`).doc(id).update({
            updatedAt: Timestamp.fromDate(new Date()),
            ...changedFields
          })
        } else {
          id = await db.collection(`projects/${this.project.id}/fieldsets`).add(this.form)
        }
        this.$emit('saved', id)
        this.$store.dispatch('snackbar/new', { type: 'success', message: 'Fieldset saved' })
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
        throw error
      } finally {
        this.saving = false
      }
    }
  }
}
</script>
