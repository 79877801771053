<template>
  <v-hover v-slot="{ hover }">
    <v-card
      outlined
      class="fire-input fire-card"
      :class="{ disabled: disabled || loading, 'fire-card--hover': hover }"
      :style="cardStlye"
      :loading="loading"
    >
      <v-card-text>
        <v-input
          :label="title"
          :hint="description"
          :persistent-hint="Boolean(description)"
          hide-details="auto"
          outlined
          :error-messages="errorMessages"
        />
      </v-card-text>
      <slot name="title" />
      <v-card-text class="pt-0">
        <slot />
      </v-card-text>
      <v-card-actions v-if="$scopedSlots.actions">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'FireCard',
  props: {
    title: {
      type: String,
      default: () => null
    },
    description: {
      type: String,
      default: () => null
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    cardStlye() {
      const style = {}
      if (this.errorMessages.length) {
        style.border = '2px solid #ff5252 !important'
      } else {
        style.borderColor = 'rgba(0, 0, 0, 0.38)'
      }
      return style
    }
  }
}
</script>

<style lang="sass">
  .fire-card
    margin-bottom: 2rem
    &.fire-card--hover
      border-color: black !important
    &.disabled
      opacity: 0.75
      position: relative
      &:after
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 2
  .v-text-field--outlined > .v-input__control > .v-input__slot
    background-color: #FFFFFF
</style>
