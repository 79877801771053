<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    slim
  >
    <fire-card
      :title="label"
      :description="description"
      :error-messages="errors"
      :disabled="disabled"
    >
      <storage-field
        v-slot="{ progress }"
        v-model="input"
        :label="label"
        asset-type="video"
        :extensions="extensions"
        @error="errors.push($event)"
      >
        <v-card
          width="200"
          height="200"
          flat
          tile
          class="d-flex"
        >
          <v-overlay
            v-if="progress"
            absolute
            class="fill-height d-flex justify-center align-center"
          >
            <v-progress-circular :value="progress" />
          </v-overlay>
          <video
            :src="value"
            controls
            preload="metadata"
            width="200"
            height="200"
            class="align-self-center"
          />
        </v-card>
      </storage-field>
    </fire-card>
  </validation-provider>
</template>

<script>
import { extensions } from './index.js'
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireVideo',
  description: 'Upload to or choose a video from firebase storage.',
  icon: 'mdi-video-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => 'video'
    },
    title: {
      type: String,
      default: () => 'Video'
    },
    extensions: {
      type: Array,
      default: () => extensions
    },
    size: {
      type: Number,
      default: () => null
    }
  },
  computed: {
    additionalRules() {
      return {
        extensions: {
          oneOf: this.extensions
        }
      }
    }
  }
}
</script>
