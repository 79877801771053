<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input fire-color"
  >
    <v-text-field
      v-model="input"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      hide-details="auto"
      outlined
      clearable
      :error-messages="errors"
      :disabled="disabled"
    >
      <template #append>
        <v-menu
          v-model="menu"
          top
          nudge-bottom="60"
          nudge-left="16"
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-avatar
              size="30"
              class="fire-color--swatch"
              :style="{ borderRadius: menu ? '50%' : '4px' }"
              v-on="on"
            >
              <v-icon
                v-if="!input"
                v-text="'mdi-palette'"
              />
              <span
                class="fire-color--swatch-bg"
                :style="{ backgroundColor: input }"
              />
            </v-avatar>
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="pickerColor"
                flat
                hide-inputs
                hide-mode-switch
                :mode="mode"
                :show-swatches="false"
                dot-size="20"
              />
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                v-if="defaultColor && input !== defaultColor"
                small
                outlined
                @click="input = defaultColor"
              >
                <v-icon
                  left
                  :color="defaultColor"
                >
                  mdi-circle
                </v-icon>
                Reset Default
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </v-text-field>
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireColor',
  description: 'Hex or hexa color field with a picker.',
  icon: 'mdi-palette-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => null
    },
    name: {
      type: String,
      default: () => 'color'
    },
    title: {
      type: String,
      default: () => 'Color'
    },
    description: {
      type: String,
      default: () => 'Click the color icon on the right to select a color.'
    },
    mode: {
      type: String,
      default: () => 'hexa'
    },
    allowHexa: {
      type: Boolean,
      default: () => true
    },
    defaultColor: {
      type: [String, Object],
      default: () => null
    }
  },
  data: () => ({
    menu: false
  }),
  computed: {
    additionalRules() {
      let regex = /^#(?:[0-9a-fA-F]{3}){1,2}$/
      if (this.allowHexa) {
        regex = /^#([A-Fa-f0-9]{6}([A-Fa-f0-9]{2})?|[A-Fa-f0-9]{3})$/
      }
      return { regex }
    },
    pickerColor: {
      get() {
        const { value } = this
        // console.log('getting', value)
        if (!value) {
          return this.allowHexa ? '#FFFFFFFF' : '#FFFFFF'
        }
        if (this.allowHexa && value.length === 7) {
          return value + 'FF'
        }
        if (!this.allowHexa && value.length > 7) {
          return value.substring(0, 7)
        }
        return value
      },
      set(value) {
        // depending on the getter the value the picker emits can be a specific format, or the full range of formats! { alpha, hex, hexa, hsla, hsva, hue, rgba }
        // console.log('setting', value)
        if (value && typeof value === 'string') {
          if (this.allowHexa) {
            if (value.substring(7, 9) === 'FF') {
              return this.$emit('input', value.substring(0, 7))
            }
            return this.$emit('input', value)
          }
          this.$emit('input', value)
        }
      }
    }
  }
}
</script>

<style lang="sass">
  .v-avatar.fire-color--swatch
    border: 1px solid grey
    cursor: pointer
    transition: border-radius 200ms ease-in-out
    position: relative
    overflow: hidden
    background-color: rgba(255, 255, 255, 0)
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat
    span.fire-color--swatch-bg
      position: absolute
      left: 0
      right: 0
      top: 0
      bottom: 0
</style>
