<template>
  <v-list>
    <div class="fire-draggable--container">
      <draggable
        :list="input"
        ghost-class="ghost"
        animation="200"
        :group="{ name: 'fields' }"
        direction="vertical"
        empty-insert-threshold="60"
        class="fire-draggable--draggable"
        handle=".draggable"
      >
        <transition-group type="transition">
          <v-card
            v-for="field in input"
            :key="field.id"
            class="fire-editable--container"
            :raised="selectedFieldId === field.id"
            flat
            color="transparent"
          >
            <v-card-actions class="fire-editable--actions">
              <v-btn
                fab
                x-small
                class="draggable cursor-grab"
              >
                <v-icon>mdi-drag-variant</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                @click="$emit('click:edit', field.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                @click="duplicateItem(field.id)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                @click="deleteItem(field.id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
            <component
              :is="field._type"
              v-bind="field"
              v-model="field.mockValue"
            />
          </v-card>
        </transition-group>
      </draggable>
      <v-card
        v-if="!input.length"
        outlined
        color="secondary lighten-4"
        class="fire-draggable--empty d-flex justify-center align-center"
        @click="$emit('click:add')"
      >
        {{ emptyText }}
      </v-card>
      <v-card-actions v-else>
        <v-btn
          block
          class="black--text"
          color="secondary lighten-4"
          @click="$emit('click:add')"
        >
          Add another field
        </v-btn>
      </v-card-actions>
    </div>
  </v-list>
</template>

<script>
import Draggable from 'vuedraggable'
import { v1 as uuidv1 } from 'uuid'
export default {
  name: 'FireFields',
  components: {
    Draggable
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    selectedFieldId: {
      type: String,
      default: () => null
    },
    emptyText: {
      type: String,
      default: () => 'Click here to add a new field'
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(list) {
        this.$emit('input', list)
      }
    }
  },
  methods: {
    duplicateItem(fieldId) {
      if (confirm('Are you sure you want to duplicate this item?')) {
        const item = this.input.find(x => x.id === fieldId)
        const currentItemIndex = this.input.indexOf(item)
        if (!currentItemIndex === null) {
          // TOTEST: duplicate error
          return alert('error')
        }
        this.$emit('input', this.input.map(x => x.id === fieldId ? [x, { ...x, id: uuidv1() }] : x).flat())
      }
    },
    deleteItem(fieldId) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$emit('input', this.input.filter(x => x.id !== fieldId))
      }
    }
  }
}
</script>

<style lang="sass">
#fireful .v-application
  .fire-draggable--container
    position: relative
    &.active
      border-color: red
    .fire-draggable--draggable
      width: 100%
      min-height: 100px
      display: flex
      flex-direction: column
      padding-left: 0
      margin-bottom: 0
    .fire-draggable--empty
      position: absolute
      opacity: 0.4
      top: 0
      left: 0
      right: 0
      bottom: 0
      border: 2px dashed #fd676b!important
  .ghost
    opacity: 0.5
    background: #c8ebfb
  // .fire-editable--container
  //   margin-top: 16px
  //   padding-bottom: 32px
  //   .fire-editable--actions
  //     position: absolute
  //     right: 0
  //     margin-top: -16px
  //     height: 32px!important
  //     background: #FFFFFF00
  //     z-index: 1
  //   .fire-input
  //     margin-bottom: 0
</style>
