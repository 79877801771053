<template>
  <v-app>
    <v-app-bar
      v-if="project && $route.name !== 'login'"
      app
      flat
      short
      dark
      color="secondary"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ project.name }}
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            fire<strong>ful</strong> v{{ require('@/../package.json').version }} βeta
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-spacer />
      <v-menu
        v-if="user"
        close-on-click
        close-on-content-click
        offset-y
      >
        <template #activator="{ on }">
          <v-btn
            icon
            text
            v-on="on"
          >
            <v-avatar v-if="user.photoURL" size="42">
              <v-img :src="user.photoURL" />
            </v-avatar>
            <v-icon v-else>
              mdi-account
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <router-view />
      <snackbars />
      <storage-uploader />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import { auth } from '@/plugins/firebase'
export default {
  name: 'App',
  computed: {
    ...mapState(['project', 'user'])
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.remove('loading')
  },
  methods: {
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      try {
        await auth.signOut()
      } catch (error) {
        this.$Sentry.captureException(error)
        this.$store.dispatch('snackbar/new', { error })
        document.getElementsByTagName('html')[0].classList.remove('loading')
      } finally {
        window.location.reload()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
html
  overflow: hidden
</style>
