import Vue from 'vue'
import Playground from './App.vue'

import store from '@/store'
import vuetify from '@/plugins/vuetify'
import { auth, db } from '@/plugins/firebase'
import '@/plugins'
import '@/components'
import '@/filters'
import '@/styles'

Vue.config.productionTip = false

const setAsyncUser = () => {
  let userLoaded = false
  return new Promise((resolve, reject) => {
    if (userLoaded) {
      resolve(auth.currentUser)
    }
    const unsubscribe = auth.onAuthStateChanged(async(user) => {
      userLoaded = true
      unsubscribe()
      if (!user) {
        // login to fireful with demo account if user is not loaded
        const newSignIn = await auth.signInWithEmailAndPassword('playground@fireful.io', 'fireful!')
        user = newSignIn.user
      }
      await store.dispatch('setUser', user)
      resolve(user)
    }, reject)
  })
}

const initApp = async() => {
  document.title = 'fireful playground'
  await setAsyncUser()
  // obtain and set the demo project
  const docRef = await db.collection('projects').doc('fireful-playground-24399').get()
  await store.dispatch('setProject', docRef.data())
  // sign into the playground project with the demo account
  await store.state.firebase.auth.signInWithEmailAndPassword('playground@fireful.io', 'fireful!')
  new Vue({
    store,
    vuetify,
    render: h => h(Playground)
  }).$mount('#app')
}
initApp()
