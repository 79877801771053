export default [
  {
    path: '/',
    component: () => import('@studio/pages/studio.vue'),
    children: [
      {
        path: '',
        name: 'index',
        component: () => import('@/components/studio/collections.vue'),
        children: [
          {
            path: ':collectionRef',
            name: 'documents',
            component: () => import('@/components/studio/documents.vue'),
            children: [
              {
                path: ':documentId',
                name: 'document',
                component: () => import('@/components/studio/document.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  // CLEAN LAYOUT
  {
    path: '/',
    component: () => import('@panel/layouts/clean.vue'),
    children: [
      { path: '/login', name: 'login', component: () => import('@studio/pages/login.vue') },
      { path: '/404', name: '404', component: () => import('@/pages/Code404.vue') }
    ]
  },
  { path: '*', redirect: { name: '404' } }
]
