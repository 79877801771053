<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    slim
  >
    <fire-card
      :title="label"
      :description="description"
      :error-messages="errors"
      :disabled="disabled"
    >
      <storage-field
        v-slot="{ progress }"
        v-model="input"
        :label="label"
        asset-type="image"
        :extensions="allExtensions"
        :size="size"
        @error="errors.push($event)"
      >
        <!-- TODO: Add a fitSquare that uses 200 from longer dimension -->
        <v-img
          :src="value | fireImage({ h: 200 })"
          :width="value ? '' : '200'"
          max-width="200"
          :height="value ? '' : '200'"
          max-height="200"
          style="background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat;"
          contain
        >
          <template #loading>
            <v-container class="fill-height d-flex justify-center align-center">
              <v-progress-circular indeterminate />
            </v-container>
          </template>
          <template v-if="progress">
            <v-container class="fill-height d-flex justify-center align-center">
              <v-progress-circular :value="progress" />
            </v-container>
          </template>
        </v-img>
      </storage-field>
    </fire-card>
  </validation-provider>
</template>

<script>
// TODO: Ask autodelete when image is removed, if it is not being used by another document
import { extensions } from './index.js'
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireImage',
  description: 'Upload or choose an image from firebase storage.',
  icon: 'mdi-image-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => 'image'
    },
    title: {
      type: String,
      default: () => 'Image'
    },
    extensions: {
      type: Array,
      default: () => extensions
    },
    width: {
      type: Number,
      default: () => null
    },
    height: {
      type: Number,
      default: () => null
    },
    size: {
      type: Number,
      default: () => null
    }
  },
  computed: {
    additionalRules() {
      return {
        extensions: {
          oneOf: [...this.allExtensions]
        }
      }
    },
    allExtensions() {
      const extensions = [...this.extensions]
      extensions.includes('jpg') && extensions.push('jpeg')
      return extensions
    }
  }
}
</script>
