<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-card
      title="Array Item"
      subtitle="What type of item does this array consist of?"
    >
      <fire-select
        v-model="value.of._type"
        :items="fields"
        item-value="name"
        item-title="title"
        item-icon="icon"
        item-subtitle="description"
        title="Item Type"
      />
      <component
        :is="arrayItemField.formComponentName"
        v-if="arrayItemField"
        :key="value.of._type"
        v-model="value.of"
        hide-field-name
        force-required
      />
    </fire-card>
    <fire-switch
      v-model="value.clearable"
      title="Clearable"
      description="Add input clear functionality."
    />
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireArrayForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => ({
        of: {
          _type: null
        }
      })
    }
  },
  data: () => ({
    fields: fields
      .filter(x => ![
        'fire-array',
        'fire-checkbox',
        'fire-checklist',
        'fire-radio',
        'fire-switch'
      ].includes(x.name)),
    field: fields.find(x => x.name === 'fire-array')
  }),
  computed: {
    fieldType: {
      get() {
        return this.value.of ? this.value.of._type : null
      },
      set(fieldType) {
        this.value.of = { _type: fieldType }
      }
    },
    arrayItemField() {
      return this.fields.find(x => x.name === this.value.of._type)
    }
  }
  // mounted() {
  //   if (!this.value.itemValue) {
  //     this.value.itemText = 'value'
  //   }
  //   if (!this.value.itemTitle) {
  //     this.value.itemTitle = 'title'
  //   }
  //   if (!this.value.itemSubtitle) {
  //     this.value.itemSubtitle = 'subtitle'
  //   }
  //   if (!this.value.itemIcon) {
  //     this.value.itemIcon = 'icon'
  //   }
  // }
}
</script>
