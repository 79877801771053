import Vue from 'vue'

const isDev = process.env.NODE_ENV === 'development'
const plainBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mMs/w8AAfMBeIBXwkoAAAAASUVORK5CYII='
const imageCdn = isDev ? 'http://localhost:5001/fireful-main/us-central1/imageTransform' : 'https://cdn.fireful.io'

const sanitizeSrc = (path) => {
  // Strip leading slash first (we'll re-add after encoding)
  path = path.replace(/^\//, '')
  if (/^https?:\/\//.test(path)) {
    // Use de/encodeURIComponent to ensure *all* characters are handled,
    // since it's being used as a path
    path = encodeURIComponent(path)
  } else {
    // Use de/encodeURI if we think the path is just a path,
    // so it leaves legal characters like '/' and '@' alone
    path = encodeURI(path).replace(/[#?:]/g, encodeURIComponent)
  }
  return path
}
const fireImage = (value, options) => {
  const src = value ? value.src || value : null
  if (!src) {
    return plainBase64
  }
  let queryString = '?src=' + sanitizeSrc(src)

  options = options || {}
  if (typeof value !== 'string') {
    options = { ...value, ...options }
  }
  if (options.extract) {
    queryString += '&extract=' + options.extract
  }
  const width = options.width || options.w
  if (width) {
    queryString += '&w=' + width
  }
  const height = options.height || options.h
  if (height) {
    queryString += '&h=' + height
  }
  if (options.fit) {
    queryString += '&fit=' + options.fit
    if (options.bg) {
      queryString += '&bg=' + encodeURIComponent(options.bg)
    }
  }
  return imageCdn + queryString
}
Vue.filter('fireImage', fireImage)

Vue.mixin({
  methods: {
    fireImage
  }
})
