<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-number
      v-model="value.rows"
      title="Default Number Of Rows"
      min-value="1"
    />
    <fire-switch
      v-model="value.autoGrow"
      title="Auto Grow"
    />
    <fire-switch
      v-model="value.noResize"
      :disabled="value.autoGrow"
      title="Remove resize handle"
    />
    <fire-switch
      v-model="value.counter"
      title="Show Counter"
      description="Displays a character count on the bottom right."
    />
    <template #validations>
      <fire-number
        v-model="value.minLength"
        title="Min Length"
        description="Enter a value to constrain the input to a minimum character length."
        integer
        min-value="1"
        :max-value="value.maxLength"
      />
      <fire-number
        v-model="value.maxLength"
        title="Max Length"
        description="Enter a value to constrain the input to a maximum character length."
        integer
        :min-value="value.minLength || 1"
      />
    </template>
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireTextForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-text')
  }),
  watch: {
    'value.autoGrow': {
      handler(autoGrow) {
        if (autoGrow && !this.value.noResize) {
          this.value.noResize = true
        }
      },
      immediate: true
    }
  }
}
</script>
