<template>
  <validation-observer
    ref="fireFieldForm"
    tag="form"
  >
    <fire-string
      v-model="value.title"
      title="Title"
      description="Pick a human readable title for your field."
      required
    />
    <fire-string
      v-if="!hideFieldName"
      v-model="value.name"
      title="Field Name"
      description="The field name in firestore."
      use-custom-rules
      :custom-rules="customRules"
      required
    />
    <template v-if="value._type">
      <fire-string
        v-if="field.props.pluralTitle"
        v-model="value.pluralTitle"
        title="Plural Title"
        :placeholder="value.title ? `${value.title}s` : ''"
        :description="value.title ? `Enter if you would like to use something other than '${value.title}s'` : ''"
      />
      <fire-string
        v-model="value.description"
        title="Description"
        description="Enter a detailed description of the field."
      />
      <fire-string
        v-if="field.props.placeholder"
        v-model="value.placeholder"
        title="Placeholder"
      />
      <fire-switch
        v-model="value.disabled"
        title="Readonly"
        description="Use to display field data from firestore without editing functionality."
      />
      <slot />
      <template v-if="hasValidations">
        <v-subheader>
          Validations
        </v-subheader>
        <v-divider class="mb-5" />
        <div v-if="field.props.useCustomRules" class="d-flex">
          <v-checkbox
            v-model="value.useCustomRules"
            class="ma-3"
          />
          <validation-provider
            v-slot="{ errors }"
            :rules="value.useCustomRules ? 'required' : ''"
            name="custom rule string"
            tag="div"
            class="fire-input flex-grow-1"
          >
            <v-text-field
              v-model="value.customRules"
              label="Use Custom Rules"
              hint="Define validations rules with a custom rule string."
              persistent-hint
              outlined
              :disabled="!value.useCustomRules"
              :error-messages="errors"
            />
          </validation-provider>
        </div>
        <template v-if="value.useCustomRules">
          <a target="_blank" href="https://logaretm.github.io/vee-validate/guide/rules.html#rules">Information about vee-validate and rules section.</a>
        </template>
        <template v-else-if="!forceRequired">
          <fire-switch
            v-model="value.required"
            title="Required"
            :description="requiredDescription"
          />
          <slot name="validations" />
        </template>
      </template>
    </template>
  </validation-observer>
</template>

<script>
import slugify from 'slugify'
import fields from './'
export default {
  name: 'FireFieldForm',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    requiredDescription: {
      type: String,
      default: () => null
    },
    hideFieldName: {
      type: Boolean,
      default: () => false
    },
    forceRequired: {
      type: Boolean,
      default: () => false
    },
    excludeFieldNames: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    fields,
    fieldNameRules: {
      'is-not': 'id'
    }
  }),
  computed: {
    field() {
      return this.value._type ? this.fields.find(x => x.name === this.value._type) : null
    },
    hasValidations() {
      return this.field.props.useCustomRules || this.value.useCustomRules || !this.forceRequired
    },
    customRules() {
      const excluded = [
        'id',
        '_createdAt',
        '_updatedAt',
        ...this.excludeFieldNames
      ]
      return `excluded:${excluded.join(',')}`
    }
  },
  watch: {
    'value.title'(title) {
      this.value.name = slugify(title, {
        replacement: '_',
        lower: true,
        strict: true
      })
    },
    'value.required': {
      handler(required) {
        if (this.forceRequired && !required) {
          this.value.required = true
        }
      },
      immediate: true
    }
  }
}
</script>
