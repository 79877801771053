<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-select
      v-model="value.to"
      title="Collection"
      :items="collections"
      item-value="ref"
      item-title="title"
    />
    <fire-card
      title="List Preview"
      description="Choose which fields will be displayed on lists."
    >
      <fire-select
        v-model="value.listPreviewTitle"
        title="Item Title"
        :items="['_createdAt', '_createdBy', '_updatedAt', ...collectionFields.map(f => f.name)]"
        clearable
      />
      <fire-select
        v-model="value.listPreviewSubtitle"
        title="Item Subtitle"
        :items="['_createdAt', '_createdBy', '_updatedAt', ...collectionFields.map(f => f.name)]"
        clearable
      />
      <fire-select
        v-model="value.listPreviewAvatar"
        title="Item Icon"
        description="Select an image or an icon field"
        :items="collectionFields.filter(f => ['fire-image', 'fire-icon'].includes(f._type)).map(f => f.name)"
        clearable
      />
    </fire-card>
    <fire-switch
      v-model="value.clearable"
      title="Clearable"
      description="Add input clear functionality."
    />
  </fire-field-form>
</template>

<script>
import { mapState } from 'vuex'
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireReferenceForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-reference')
  }),
  computed: {
    ...mapState('firebase', ['collections']),
    collectionFields() {
      return this.collections.find(x => x.ref === this.value.to)?.fields || []
    }
  }
}
</script>
