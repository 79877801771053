<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label"
    tag="div"
    class="fire-input"
  >
    <v-autocomplete
      v-model="input"
      :items="mdiIcons"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      outlined
      :required="required"
      :error-messages="errors"
      :clearable="clearable"
      :disabled="disabled"
      class="v-input__append-inner--centered"
    >
      <template #selection="{ item }">
        <v-list-item dense style="width: 100%;">
          <v-list-item-avatar>
            <v-icon>
              mdi-{{ item }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #item="{ item }">
        <v-list-item-avatar>
          <v-icon>
            mdi-{{ item }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </validation-provider>
</template>

<script>
import mdiIcons from './assets/mdi-icons'
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireIcon',
  description: 'An icon selector',
  icon: 'mdi-emoticon-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: String,
      default: () => null
    },
    name: {
      type: String,
      default: () => 'icon'
    },
    title: {
      type: String,
      default: () => 'Icon'
    },
    clearable: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    mdiIcons
  })
}
</script>
