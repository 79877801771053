<template>
  <v-app-bar
    app
    flat
    short
    clipped-right
    dark
    color="primary"
  >
    <v-app-bar-nav-icon
      v-if="project"
      class="hidden-md-and-up"
      @click="drawer = !drawer "
    />
    <router-link v-if="!project" :to="{ name: 'index' }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            fire<span class="font-weight-bold">ful</span> panel
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            version {{ require('@/../package.json').version }} βeta
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </router-link>
    <v-menu
      v-if="project"
      max-height="300"
    >
      <template #activator="{ on }">
        <v-btn
          text
          :ripple="false"
          style="text-transform: none;"
          v-on="on"
        >
          {{ project.name }}
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-card outlined width="300">
        <v-list-item exact :to="{ name: 'index' }">
          See all projects
        </v-list-item>
        <v-list-item :to="{ name: 'new-project' }">
          Add a project
        </v-list-item>
        <v-divider />
        <v-subheader>
          My Projects
        </v-subheader>
        <v-list-item
          v-for="project in $store.state.projects"
          :key="project.id"
          :to="{ name: 'project-index', params: { id: project.id } }"
          active-class="primary--text"
        >
          <v-list-item-title>{{ project.name }}</v-list-item-title>
        </v-list-item>
      </v-card>
    </v-menu>
    <v-spacer />
    <v-btn
      href="https://fireful.io/changelog"
      target="_blank"
      class="mx-sm-2 hidden-xs-only"
      color="white"
      text
    >
      Changelog
    </v-btn>
    <v-btn
      href="https://fireful.io/docs"
      target="_blank"
      class="mx-sm-2 hidden-xs-only"
      color="white"
      text
    >
      Docs
    </v-btn>
    <!-- LATER: When studio is enabled -->
    <!-- <v-btn
      v-if="project"
      class="mr-4"
      small
      color="secondary"
      :icon="!$vuetify.breakpoint.smAndUp"
      :href="studioUrl"
      target="_blank"
    >
      <v-icon left>
        mdi-open-in-new
      </v-icon>
      {{ $vuetify.breakpoint.smAndUp ? 'Open Studio' : '' }}
    </v-btn> -->
    <v-menu
      close-on-click
      close-on-content-click
      offset-y
    >
      <template #activator="{ on }">
        <v-btn
          icon
          text
          v-on="on"
        >
          <v-avatar color="secondary" size="42">
            <v-img class="v-image--avatar" :src="user.photoURL">
              <template #placeholder>
                {{ user.displayName | initials(2) }}
              </template>
            </v-img>
          </v-avatar>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader class="px-6">
          {{ user.email }}
        </v-subheader>
        <v-list-item
          href="https://fireful.io/changelog"
          target="_blank"
          class="hidden-sm-and-up"
        >
          <v-icon small left>
            mdi-open-in-new
          </v-icon>
          <v-list-item-title>Changelog</v-list-item-title>
        </v-list-item>
        <v-list-item
          href="https://fireful.io/docs"
          target="_blank"
          class="hidden-sm-and-up"
        >
          <v-icon small left>
            mdi-open-in-new
          </v-icon>
          <v-list-item-title>Docs</v-list-item-title>
        </v-list-item>
        <v-list-item @click="help">
          <v-icon small left>
            mdi-help
          </v-icon>
          <v-list-item-title>Help</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="logout">
          <v-icon small left>
            mdi-logout
          </v-icon>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { auth } from '@/plugins/firebase'
export default {
  name: 'AppBar',
  computed: {
    ...mapState(['user']),
    ...mapGetters(['project']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(drawerState) {
        this.$store.commit('SET_DRAWER', drawerState)
      }
    },
    studioUrl() {
      return process.env.NODE_ENV === 'development'
        ? 'http://dev.studio.fireful.io'
        : `https://${this.project.id}.fireful.studio`
    }
  },
  methods: {
    help() {
      window.tidioChatApi.open()
    },
    async logout() {
      document.getElementsByTagName('html')[0].classList.add('loading')
      try {
        await auth.signOut()
        window.localStorage.removeItem('accessToken')
        window.location.reload()
      } catch (error) {
        alert(error)
        const html = document.getElementsByTagName('html')[0]
        html.className = html.className.replace(/\bloading\b/, '')
      }
    }
  }
}
</script>

<style>
.v-image--avatar .v-image__placeholder{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6em;
}
</style>
