export default {
  props: {
    // field name
    name: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    description: {
      type: String,
      default: () => ''
    },
    required: {
      type: Boolean,
      default: () => false
    },
    // readonly: {
    //   type: Boolean,
    //   default: () => false
    // },
    disabled: {
      type: Boolean,
      default: () => false
    },
    validate: {
      type: Boolean,
      default: () => true
    },
    autofocus: {
      type: Boolean,
      default: () => false
    },
    customRules: {
      type: [String, Object],
      default: () => null
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    label() {
      return `${this.title}${this.required ? '*' : ''}`
    },
    additionalRules() {
      return {}
    },
    rules() {
      if (!this.validate) {
        return ''
      }
      const required = Boolean(this.required)
      if (this.useCustomRules) {
        let customRules = this.customRules
        if (required) {
          customRules = `${customRules}|required`
        }
        return customRules
      }
      // overwrite required rule if exists in additionalRules
      // make sure additional rules are an interable object before adding in
      return { required, ...this.additionalRules }
    }
  }
}
