<template>
  <v-app>
    <layout-app-bar v-if="user" />
    <layout-project-navigation v-if="project" />
    <v-main>
      <router-view />
      <snackbars />
      <storage-uploader />
      <firebase-errors />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState(['user']),
    ...mapGetters(['project'])
  },
  watch: {
    'user.uid': {
      immediate: true,
      handler(uid) {
        if (!uid) {
          return
        }
        const { email, displayName, metadata } = this.user
        // identify tidio user
        const data = {
          distinct_id: uid,
          email,
          name: displayName
        }
        if (!window.tidioChatApi) {
          document.tidioIdentify = data
        } else {
          window.tidioChatApi.setVisitorData(data)
        }
        // identify userpilot user
        // LATER: Add userpilot
        window.userpilot && window.userpilot.identify(uid, {
          name: displayName,
          email,
          created_at: parseInt(metadata.a / 1000)
        })
      }
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.remove('loading')
  }
}
</script>
