<template>
  <validation-provider
    v-slot="{ errors }"
    ref="field"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <v-text-field
      v-model="stringInput"
      :label="label"
      :hint="description"
      :persistent-hint="Boolean(description)"
      hide-details="auto"
      outlined
      :placeholder="placeholder"
      :error-messages="errors"
      :disabled="disabled"
    />
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireNumber',
  description: 'A number field.',
  icon: 'mdi-numeric',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: [String, Number],
      default: () => null
    },
    name: {
      type: String,
      default: () => 'number'
    },
    title: {
      type: String,
      default: () => 'Number'
    },
    placeholder: {
      type: [String, Number],
      default: () => null
    },
    integer: {
      type: Boolean,
      default: () => false
    },
    disallowNegative: {
      type: Boolean,
      default: () => false
    },
    minValue: {
      type: [Number, String],
      default: () => null
    },
    maxValue: {
      type: [Number, String],
      default: () => null
    },
    useCustomRules: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    stringInput: {
      get() {
        return this.input
      },
      set(input) {
        if (input === '') {
          this.input = null
          return
        }
        const number = input.match(this.rules.regex)
        if (!number) {
          this.input = input
          return
        }
        const float = parseFloat(number[0])
        this.input = isNaN(float) ? input : float
      }
    },
    additionalRules() {
      const rules = {}
      rules.regex = /^[-]?[0-9]\d*(\.\d+)?$/
      if (this.integer) {
        rules.integer = true
      }
      let minValue = this.integer
        ? parseInt(this.minValue)
        : parseFloat(this.minValue)
      if (this.disallowNegative && (!minValue || minValue < 0)) {
        minValue = 0
      }
      const maxValue = this.integer
        ? parseInt(this.maxValue)
        : parseFloat(this.maxValue)
      if (!isNaN(minValue)) {
        if (!isNaN(maxValue)) {
          rules.between = { min: minValue, max: maxValue }
        } else {
          rules.min_value = minValue
        }
      } else if (!isNaN(maxValue)) {
        rules.max_value = maxValue
      }
      return rules
    }
  }
}
</script>
