<template>
  <div class="fire-column--container v-dialog--scrollable">
    <template v-if="history">
      <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical />
      <v-card flat class="fire-column">
        <v-card-title dense flat>
          History
          <v-spacer />
          <v-btn
            icon
            @click="history = null"
          >
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-card-title>
        <template v-if="history === 'loading'">
          <v-card-text>
            <v-skeleton-loader
              v-for="n in 10"
              :key="n"
              type="list-item-avatar-two-line"
            />
          </v-card-text>
        </template>
        <v-card-text v-else>
          <v-list-item
            v-for="(item, i) in history"
            :key="i"
            @click="selectedHistoryItem = item"
          >
            <v-list-item-avatar
              size="24"
              :color="HISTORY_ITEM_COLORS[item.action]"
            >
              <v-icon small dark v-text="HISTORY_ITEM_ICONS[item.action]" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.action | capitalizeFirstLetter }} by {{ item.user.displayName || item.user.email }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.time | formatDate }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </template>
    <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical />
    <validation-observer
      v-if="collection && editDocument"
      :key="editDocument.id"
      ref="form"
      v-slot="{ changed }"
      class="fire-column"
      tag="form"
      @submit="submit"
    >
      <v-card flat class="fire-column">
        <v-card-title>
          <v-btn
            v-if="$vuetify.breakpoint.xsOnly"
            icon
            exact
            class="mr-4"
            :to="{ name: 'documents', params: { collectionName: collection.name } }"
          >
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          {{ `${newDocument ? 'New' : selectedHistoryItem ? 'Restore' : 'Edit'} ${collection.title || 'Document'}` }}
          <v-spacer />
          <v-btn
            v-if="newDocument && $vuetify.breakpoint.smAndUp"
            icon
            color="primary"
            exact
            :to="{ name: 'documents', params: { collectionName: collection.name } }"
          >
            <v-icon v-text="'mdi-close'" />
          </v-btn>
          <v-btn
            v-if="selectedHistoryItem"
            icon
            color="primary"
            @click="selectedHistoryItem = null"
          >
            <v-icon v-text="'mdi-close'" />
          </v-btn>
          <v-menu
            v-else-if="!newDocument && collection"
            transition="fade-transition"
            bottom
            left
          >
            <template #activator="{ on }">
              <v-btn
                icon
                color="primary"
                v-on="on"
                @click.stop.prevent=""
              >
                <v-icon v-text="'mdi-dots-vertical'" />
              </v-btn>
            </template>
            <v-list dense width="200">
              <v-subheader class="pl-5">
                {{ collection.title }} Options
              </v-subheader>
              <v-btn
                icon
                large
                style="position: absolute; right: 0.1em; top: 0.1em;"
              >
                <v-icon v-text="'mdi-close'" />
              </v-btn>
              <!-- <v-list-item
                v-if="collection && collection.keepHistory && history === null"
                @click="showHistory"
              >
                <v-list-item-icon>
                  <v-icon v-text="'mdi-history'" />
                </v-list-item-icon>
                <v-list-item-title>
                  Show History
                </v-list-item-title>
              </v-list-item> -->
              <template v-if="!selectedHistoryItem">
                <v-list-item
                  v-if="collection.create !== false"
                  :loading="duplicating"
                  @click="duplicate"
                >
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-content-copy'" />
                  </v-list-item-icon>
                  <v-list-item-title>
                    Duplicate
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="collection.delete !== false"
                  :loading="deleting"
                  @click="remove"
                >
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-delete'" />
                  </v-list-item-icon>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item
                  :loading="duplicating"
                  @click="duplicate"
                >
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-content-copy'" />
                  </v-list-item-icon>
                  <v-list-item-title>
                    Duplicate Historical Version
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :loading="deleting"
                  @click="remove"
                >
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-delete'" />
                  </v-list-item-icon>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-subtitle v-if="error">
          <v-alert
            dense
            outlined
            border="left"
            type="error"
            class="mb-0"
          >
            {{ error.message }}
          </v-alert>
        </v-card-subtitle>
        <v-divider />
        <v-card-text>
          <template v-for="field in collection.fields">
            <template v-if="loading">
              <v-card
                :key="field.id"
                outlined
                class="fire-input"
              >
                <v-skeleton-loader
                  :type="field._type | fieldSkeleton"
                  class="mx-auto"
                />
              </v-card>
            </template>
            <template v-else-if="!selectedHistoryItem">
              <component
                :is="field._type"
                :key="field.id"
                v-model="editDocument[field.name]"
                v-bind="field"
                :collection-name="collection.name"
                :document-id="$route.params.documentId"
              />
            </template>
            <template v-else>
              <component
                :is="field._type"
                :key="field.id"
                v-model="selectedHistoryDoc[field.name]"
                v-bind="field"
                :collection-name="collection.name"
                :document-id="selectedHistoryDoc.id"
                disabled
              />
            </template>
          </template>
          <v-card
            v-if="fieldErrors.length"
            outlined
          >
            <v-card-title>
              <v-icon color="error" large left>
                mdi-alert-rhombus-outline
              </v-icon>
              {{ fieldErrors.length }} undefined field value{{ fieldErrors.length > 1 ? 's' : '' }}
            </v-card-title>
            <v-divider />
            <v-card-text>
              Undefined field values are values currently present in the firestore document yet are not defined in the current collection schema.
            </v-card-text>
            <v-list>
              <v-list-item
                v-for="field in fieldErrors"
                :key="field"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ field }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="white-space-normal"
                    style="word-break: break-all;"
                  >
                    {{ editDocument[field].toString() }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    small
                    color="error"
                    @click="unsetField(field)"
                  >
                    Remove
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <template v-if="!selectedHistoryItem">
            <v-subheader v-if="!newDocument">
              Last Updated {{ (editDocument._updatedAt || editDocument._createdAt) | formatDate }}
            </v-subheader>
            <v-spacer />
            <v-btn
              color="primary"
              type="submit"
              text
              :loading="saving"
              :disabled="!changed || saving"
              @click="submit"
            >
              {{ changed ? 'Save' : 'No Changes' }}
            </v-btn>
          </template>
          <template v-else>
            <v-subheader>
              Version at {{ selectedHistoryItem.time | formatDate }}
            </v-subheader>
            <v-spacer />
            <v-btn
              color="warning"
              text
              :loading="restoring"
              :disabled="!restorable"
              @click="restore"
            >
              {{ restorable ? 'Restore' : 'No Changes' }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </div>
</template>

<script>
// TODO: Play with the merge option
// TODO: Remove all history related stuff from the beta
import { mapState, mapGetters } from 'vuex'
import { stringify } from 'flatted/esm'
import { clone } from 'lodash-es'
import { Timestamp, FieldValue, db } from '@/plugins/firebase'
import { nestedStorageReferencesSearch } from '@/utils/helpers'
export default {
  name: 'Document',
  data: () => ({
    loading: true,
    editDocument: {
      id: null
    },
    history: null,
    selectedHistoryItem: null,
    saving: false,
    duplicating: false,
    restorable: false,
    restoring: false,
    deleting: false,
    error: null,
    unsubscribe: null,
    HISTORY_ITEM_COLORS: {
      restored: 'accent',
      updated: 'warning',
      created: 'success'
    },
    HISTORY_ITEM_ICONS: {
      restored: 'mdi-history',
      updated: 'mdi-pencil',
      created: 'mdi-check'
    }
  }),
  computed: {
    ...mapState('firebase', ['db', 'collections', 'team']),
    ...mapGetters(['project']),
    newDocument() {
      return this.$route.params.documentId === 'new'
    },
    collection() {
      return this.collections.find(x => x.ref === this.$route.params.collectionRef)
    },
    audits() {
      return db.collection(`projects/${this.project.id}/audits`)
    },
    fieldErrors() {
      return Object.keys(this.editDocument)
        .filter(x => !['_createdAt', '_createdBy', '_updatedAt', 'id', ...this.collection.fields.map(f => f.name)].includes(x))
    },
    selectedHistoryDoc() {
      if (!this.selectedHistoryItem) {
        return null
      }
      return this.retrieveAudit(this.selectedHistoryItem.doc)
    }
  },
  watch: {
    '$route.params.documentId': {
      async handler(documentId) {
        this.loading = true
        if (documentId === 'new') {
          this.history = null
          this.editDocument = {}
          setTimeout(() => {
            this.loading = false
          }, 200)
          return
        }
        await this.loadDocument()
        this.history && this.showHistory()
        this.selectedHistoryItem = null
        this.loading = false
      },
      immediate: true
    },
    history(val) {
      if (!val) {
        this.selectedHistoryItem = null
        this.unsubscribe && this.unsubscribe()
      }
    },
    selectedHistoryItem(item) {
      if (item) {
        const { doc } = item
        const diff = this.collection.fields.map(f => f.name).filter((field) => {
          return stringify(doc[field]) !== stringify(this.editDocument[field])
        })
        this.restorable = diff.length > 0
      }
    }
  },
  beforeCreate() {
    if (this.newDocument && this.collection.create === false) {
      alert('creating is not allowed on this collection')
      this.$router.push({ name: 'documents', params: { collectionRef: this.collection.name } })
    }
  },
  methods: {
    async loadDocument() {
      const editDocument = await this.db.collection(this.collection.ref).doc(this.$route.params.documentId).get()
      this.editDocument = { id: editDocument.id, ...editDocument.data() }
    },
    showHistory() {
      if (!this.collection.keepHistory) {
        return false
      }
      this.unsubscribe && this.unsubscribe()
      this.history = 'loading'
      const { id } = this.editDocument
      this.unsubscribe = this.audits.doc(id).onSnapshot(this.snapshotHistory)
    },
    snapshotHistory(doc) {
      if (!doc.exists) {
        this.history = []
        return
      }
      const { history } = doc.data()
      this.history = (history || []).reverse().map((item) => {
        item.user = item.userId === this.$store.state.user.uid
          ? this.$store.state.user
          : this.team.find(u => u.uid === item.userId)
        return item
      })
    },
    async submit() {
      this.saving = true
      try {
        const doc = {}
        Object.keys(this.editDocument).filter(x => x !== 'id').forEach((field) => {
          const value = this.editDocument[field]
          if (typeof value !== 'undefined') {
            doc[field] = value
          }
        })
        const time = Timestamp.fromDate(new Date())
        let { id } = this.editDocument
        if (!id) {
          if (this.collection.create === false) {
            return
          }
          doc._createdAt = time
          doc._createdBy = this.$store.state.user.uid
          const newDocument = await this.$store.state.firebase.db.collection(this.collection.ref).add(doc)
          id = newDocument.id
          // audits
          if (this.collection.keepHistory) {
            await this.audits.doc(id).set({
              collectionRef: this.collection.ref,
              history: [{
                time,
                action: 'created',
                userId: this.$store.state.user.uid,
                doc
              }]
            })
            this.$router.push({ name: 'document', params: { documentId: id } })
          }
        } else {
          if (this.collection.update === false) {
            return
          }
          doc._updatedAt = time
          await this.db.collection(this.collection.ref).doc(id)
            .set(doc, { merge: true })

          // audits
          if (this.collection.keepHistory) {
            const audits = await this.audits.doc(id).get()
            const history = audits.exists ? (audits.data().history || []) : []
            history.push({
              time,
              action: 'updated',
              userId: this.$store.state.user.uid,
              doc: this.auditizeDoc(doc)
            })
            if (audits.exists) {
              await this.audits.doc(id).update({ history })
            } else {
              await this.audits.doc(id).set({ history })
            }
          }
          await this.loadDocument()
        }
        await this.updateDocumentReferences({ id, doc })
        this.$refs.form.reset()
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
        this.error = error
      } finally {
        this.saving = false
      }
    },
    async duplicate() {
      if (this.collection.create === false) {
        return
      }
      try {
        this.duplicating = true
        const doc = {}
        Object.keys(this.editDocument)
          .filter(x => x !== 'id')
          .forEach((field) => {
            const value = this.editDocument[field]
            if (typeof value !== 'undefined') {
              doc[field] = value
            }
          })
        const now = Timestamp.fromDate(new Date())
        doc._createdAt = now
        delete doc._updatedAt
        const { id } = await this.db.collection(this.collection.ref).add(doc)
        this.$store.dispatch('snackbar/new', { type: 'success', message: 'Document duplicated' })
        this.$router.push({ name: 'document', params: { documentId: id } })
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
      } finally {
        this.duplicating = false
      }
    },
    async remove() {
      if (this.collection.delete === false) {
        return
      }
      try {
        this.deleting = true
        await this.db.collection(this.collection.ref).doc(this.$route.params.documentId).delete()
        this.$router.push({ name: 'documents', params: { collectionId: this.collection.id } })
        this.$store.dispatch('snackbar/new', { type: 'success', message: 'Document deleted' })
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
      } finally {
        this.deleting = false
      }
    },
    async restore() {
      if (!this.collection.keepHistory) {
        return false
      }
      this.restoring = true
      try {
        const doc = {}
        Object.keys(this.selectedHistoryDoc).filter(x => x !== 'id').forEach((field) => {
          doc[field] = this.selectedHistoryDoc[field]
        })
        const time = Timestamp.fromDate(new Date())
        doc._updatedAt = time
        const { id } = this.editDocument
        await this.db.collection(this.collection.ref).doc(id)
          .set(doc, { merge: true })
        const audits = await this.audits.doc(id).get()
        const history = audits.exists ? audits.data().history : []
        history.push({
          time,
          action: 'restored',
          userId: this.$store.state.user.uid,
          doc
        })
        await this.audits.doc(id).update({ history })
        this.$store.dispatch('snackbar/new', { type: 'success', message: 'Document restored.' })
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
      }
      this.restoring = false
    },
    async unsetField(field) {
      try {
        await this.db
          .collection(this.collection.ref)
          .doc(this.editDocument.id)
          .update({
            _updatedAt: Timestamp.fromDate(new Date()),
            [field]: FieldValue.delete()
          })
        await this.loadDocument()
        this.$store.dispatch('snackbar/new', { type: 'success', message: 'Field value deleted' })
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
      }
    },
    auditizeDoc(sourceDocument) {
      const auditDocument = {}
      for (const key of Object.keys(sourceDocument)) {
        const sourceValue = clone(sourceDocument[key])
        if (sourceValue?.firestore?.app?.name_ === this.project.id) {
          auditDocument[key] = {
            _type: 'fire-reference',
            id: sourceValue.id,
            path: sourceValue.path
          }
          continue
        }
        auditDocument[key] = sourceValue
      }
      return auditDocument
    },
    retrieveAudit(auditDocument) {
      const restoreDocument = {}
      for (const key of Object.keys(auditDocument)) {
        const auditValue = clone(auditDocument[key])
        if (auditValue?._type === 'fire-reference') {
          const { path, id } = auditValue
          const collectionPath = path.replace(`/${id}`, '')
          restoreDocument[key] = this.db.collection(collectionPath).doc(id)
          continue
        }
        restoreDocument[key] = auditValue
      }
      return restoreDocument
    },
    /// ///////////////////////////////////////////////////
    // MVP: update storage item connections in this document //
    // search recursive inside the doc to find storage connections
    // update the related storage document to add connections
    // search for other storage items referring to this document
    // -> if they are not present in the doc, remove them.
    /// ///////////////////////////////////////////////////
    async updateDocumentReferences({ id, doc }) {
      if (!id) {
        throw new Error('updateDocumentReferences called without document id')
      }
      const storageReferences = nestedStorageReferencesSearch(doc)
      await this.audits.doc(id).set({ storageReferences }, { merge: true })
    }
  }
}
</script>

<style lang="sass">
  form.document-form
    height: 100%
    > .v-card
      height: 100%
      display: flex
      flex-direction: column
      > .v-card__text
        flex: 1
        overflow: auto
      > .v-card__actions
        flex: 0
</style>
