import { v1 as uuidv1 } from 'uuid'

export const state = () => ({
  items: []
})

// getters
export const getters = {
}

// mutations
export const mutations = {
  NEW_SNACKBAR(state, snackbar) {
    snackbar.id = snackbar.id || uuidv1()
    state.items.push(snackbar)
  },
  DISMISS_SNACKBAR(state, snackbarId) {
    state.items = state.items.filter(x => x.id !== snackbarId)
  }
}

// actions
export const actions = {
  new: ({ commit }, snackbar) => {
    if (snackbar.error) {
      // eslint-disable-next-line
      console.warn('error', snackbar.error)
      if (!snackbar.type) {
        snackbar.type = 'error'
      }
      if (!snackbar.message) {
        snackbar.message = snackbar.error.message
      }
    }
    commit('NEW_SNACKBAR', snackbar)
  },
  dismiss: ({ commit }, snackbarId) => {
    commit('DISMISS_SNACKBAR', snackbarId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
