import Vue from 'vue'
import moment from 'moment'
moment.locale('en')

Vue.filter('formatUnixDate', (value, format) => {
  return moment.unix(value).format(format)
})
export const formatDate = (value, format = 'DD.MM.YYYY HH:mm') => {
  if (!value) {
    return null
  }
  if (typeof value === 'object' && typeof value.toDate === 'function') {
    value = value.toDate()
  }
  if (`${value}`.length === 10) {
    value = value * 1000
  }
  return moment(value).format(format)
}
Vue.filter('formatDate', formatDate)

Vue.filter('formatDuration', (value) => {
  const duration = moment.duration(value, 'seconds')
  let hours = duration.hours()
  let minutes = duration.minutes()
  let seconds = duration.seconds()
  hours = hours ? `${hours}:` : ''
  if (!minutes) {
    minutes = '00'
  }
  if (!seconds) {
    seconds = '00'
  } else if (seconds < 10) {
    seconds = `0${seconds}`
  }
  return `${hours}${minutes}:${seconds}`
})

Vue.mixin({
  methods: {
    formatDate
  }
})
