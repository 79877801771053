import firebase from 'firebase/app'
import { firestoreAction } from 'vuexfire'
import { initializeApp, db, functions } from '@/plugins/firebase'

const fetchProjectTeam = functions.httpsCallable('fetchProjectTeam')

function generatePassword(length = 16) {
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowercase = 'abcdefghijklmnopqrstuvwxyz'
  const numbers = '0123456789'
  const symbols = '!"#$%&\'()*+,-./:;<=>?@^[\\]^_`{|}~'
  const all = uppercase + lowercase + numbers + symbols
  let password = ''
  for (let index = 0; index < length; index++) {
    const character = Math.floor(Math.random() * all.length)
    password += all.substring(character, character + 1)
  }
  return password
}

export const state = () => ({
  ready: false,
  app: null,
  auth: null,
  db: null,
  storage: null,
  user: null,
  collections: [],
  fieldsets: [],
  apiKey: null,
  authError: null,
  team: []
})

// getters
export const getters = {}

// mutations
export const mutations = {
  INITIALIZE_APP(state, firebaseConfig) {
    const { projectId } = firebaseConfig
    const app = firebase.apps.find(x => x.name_ === projectId) || initializeApp(firebaseConfig, projectId)
    state.app = app
    state.auth = app.auth()
    state.db = app.firestore()
    state.storage = app.storage()
    state.apiKey = firebaseConfig.apiKey
  },
  CLOSE_APP(state) {
    state.app = null
    state.auth = null
    state.db = null
    state.storage = null
  },
  CLEAR_PROJECT_FIREBASE(state, firebaseConfig) {
    const { projectId } = firebaseConfig
    const app = firebase.apps.find(x => x.name_ === projectId) || initializeApp(firebaseConfig, projectId)
    state.app = app
    state.auth = app.auth()
    state.db = app.firestore()
    state.storage = app.storage()
  },
  SET_STUDIO_USER(state, user) {
    state.user = user
  },
  SET_STUDIO_TEAM(state, team) {
    state.team = team
  },
  SET_AUTH_ERROR(state, error) {
    state.authError = error
  },
  SET_APP_READY(state, ready) {
    state.ready = ready
  }
}

// actions
export const actions = {
  bindProject: firestoreAction(async({ state, commit, dispatch, bindFirestoreRef, unbindFirestoreRef }, project) => {
    commit('SET_APP_READY', false)
    commit('SET_AUTH_ERROR', null)
    if (project && project.firebaseConfig) {
      commit('INITIALIZE_APP', project.firebaseConfig)
      state.auth.onAuthStateChanged((user) => {
        user ? commit('SET_STUDIO_USER', user) : dispatch('loginWithStudioUser')
      })
      await bindFirestoreRef('collections', db.collection(`projects/${state.app.name}/collections`))
      await bindFirestoreRef('fieldsets', db.collection(`projects/${state.app.name}/fieldsets`))
      if (state.app.name !== 'fireful-playground-24399') {
        const { data } = await fetchProjectTeam(state.app.name)
        commit('SET_STUDIO_TEAM', data)
      }
    } else {
      commit('CLOSE_APP')
      await unbindFirestoreRef('collections')
      await unbindFirestoreRef('fieldsets')
      commit('SET_STUDIO_TEAM', [])
    }
    commit('SET_APP_READY', true)
  }),
  getAsyncCurrentUser: ({ commit, state }) => {
    return new Promise((resolve, reject) => {
      const unsubscribeUser = state.auth.onAuthStateChanged((user) => {
        unsubscribeUser()
        commit('SET_STUDIO_USER', user)
        resolve(user)
      }, reject)
    })
  },
  loginWithStudioUser: async({ state, commit, dispatch }) => {
    const projectId = state.app.name
    const doc = await db.collection('studio-users').doc(projectId).get()
    if (doc.exists) {
      try {
        await state.auth.signInWithEmailAndPassword(`${projectId}@fireful.studio`, doc.data().password)
        return
      } catch (error) {
        if (error.code === 'auth/user-not-found') {
          await db.collection('studio-users').doc(projectId).delete()
          return dispatch('loginWithStudioUser')
        }
        if (error.code === 'auth/wrong-password') {
          error.tip = `Please delete the user "${projectId}@fireful.studio" from your firebase console and try again.`
        }
        if (error.code === 'auth/operation-not-allowed') {
          error.tip = 'Please check your project authentication settings to see if Email/Password login in enabled and retry.'
        }
        if (error.code === 'auth/user-disabled') {
          error.tip = `Please check if the user "${projectId}@fireful.studio" is enabled in your firebase console. If so, please enable the account and try again.`
        }
        if (error.code === 'auth/too-many-requests') {
          error.message = 'Access to your studio account has been temporarily disabled due to many failed login attempts.'
          error.tip = `You may try deleting the user "${projectId}@fireful.studio" from your firebase console or you can try again later.`
        }
        return commit('SET_AUTH_ERROR', error)
      }
    }
    const password = generatePassword()
    await db.collection('studio-users').doc(projectId).set({ password })
    try {
      await state.auth.createUserWithEmailAndPassword(`${projectId}@fireful.studio`, password)
    } catch (error) {
      if (error.code === 'auth/operation-not-allowed') {
        error.tip = 'Please check your project authentication settings to see if Email/Password login in enabled and retry.'
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
