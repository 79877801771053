<template>
  <v-container pa-0>
    <v-row class="flex-wrap">
      <!-- TODO: Fix the sizing of the preview -->
      <v-col class="flex-grow-0" style="min-width: 200px">
        <slot :progress="uploadProgress" :filename="filename" />
      </v-col>
      <v-col class="d-flex flex-column justify-start">
        <v-btn
          class="ma-2"
          outlined
          @click.stop="$refs.uploadButton.click()"
        >
          <v-icon left v-text="'mdi-cloud-upload-outline'" />
          Upload
          <input
            ref="uploadButton"
            type="file"
            class="fire-input--file"
            @change="fileSelected"
          >
        </v-btn>
        <v-btn
          class="ma-2"
          outlined
          @click="browse = true"
        >
          <v-icon left v-text="'mdi-image-search-outline'" />
          Browse
        </v-btn>
        <v-btn
          v-if="value"
          color="error"
          class="ma-2"
          outlined
          @click="remove"
        >
          <v-icon left v-text="'mdi-delete'" />
          Remove
        </v-btn>
      </v-col>
    </v-row>
    <storage-browser
      v-model="browse"
      :asset-type="assetType"
      :directory="directory"
      :extensions="extensions"
      :size="size"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TaskState } from './../../plugins/firebase'
import { unpackDownloadURL } from './../../utils/helpers'
export default {
  name: 'StorageField',
  props: {
    value: {
      type: String,
      default: () => null
    },
    assetType: {
      type: String,
      default: () => null
    },
    directory: {
      type: String,
      default: () => ''
    },
    extensions: {
      type: Array,
      default: () => []
    },
    size: {
      type: Number,
      default: () => null
    }
  },
  data: () => ({
    file: null,
    fileCounter: 0,
    uploadTask: null,
    uploadState: null,
    uploadProgress: null,
    browse: false
  }),
  computed: {
    ...mapState('firebase', [
      'app',
      'storage',
      'user'
    ]),
    filename() {
      if (this.value) {
        const { filename } = unpackDownloadURL(this.value)
        if (filename) {
          return filename
        }
      }
      if (!this.file || !this.file.name) {
        return null
      }
      if (!this.fileCounter) {
        return this.file.name
      }
      const splitFilename = this.file.name.split('.')
      const ext = splitFilename.splice(-1, 1)[0]
      const filename = splitFilename.join('.')
      return `${filename} (${this.fileCounter}).${ext}`
    },
    fileRef() {
      if (!this.storage || !this.filename) {
        return
      }
      const storageRef = this.storage.ref()
      if (!this.directory) {
        return storageRef.child(this.filename)
      }
      return storageRef.child(`${this.directory}/${this.filename}`)
    }
  },
  watch: {
    browse(val) {
      if (typeof val === 'object' && val.meta) {
        if (this.browseValidate(val)) {
          this.$emit('input', val.url)
          this.browse = false
          return
        }
        this.browse = true
      }
    }
  },
  methods: {
    ...mapActions('firebase', [
      'getAsyncCurrentUser'
    ]),
    browseValidate(item) {
      // validate file extension
      if (this.extensions && this.extensions.length) {
        const ext = item.name.split('.').reverse()[0]
        if (!this.extensions.includes(ext.toLowerCase())) {
          alert(`${this.capitalizeFirstLetter(this.assetType)} has to be one of the following formats: ${this.extensions.join(', ')}`)
          return false
        }
      }
      // validate file size
      if (this.size && item.meta.size > this.size) {
        alert(`${this.capitalizeFirstLetter(this.assetType)} size cannot exceed ${this.size} KB`)
        return false
      }
      return true
    },
    async fileSelected(e) {
      this.file = e.target.files[0]
      if (!this.file) {
        this.$emit('input', null)
        return
      }
      if (this.beforeUploadValidate()) {
        !this.user && await this.getAsyncCurrentUser()
        this.$emit('input', null)
        this.fileCounter = 0
        this.fileRef.getDownloadURL().then(this.onResolve, this.onReject)
      }
    },
    beforeUploadValidate() {
      // check that the file has a name
      if (!this.file.name) {
        return
      }
      // validate filename extension
      if (this.extensions && this.extensions.length) {
        const ext = this.file.name.split('.').reverse()[0]
        if (!this.extensions.includes(ext.toLowerCase())) {
          this.$emit('error', `${this.capitalizeFirstLetter(this.assetType)} has to be one of the following formats: ${this.extensions.join(', ')}`)
          return false
        }
      }
      // validate file size
      if (this.size && this.file.size > this.size) {
        this.$emit('error', `${this.capitalizeFirstLetter(this.assetType)} size cannot exceed ${this.size} KB`)
        return false
      }
      return true
    },
    onResolve(foundURL) {
      this.fileCounter++
      this.fileRef.getDownloadURL().then(this.onResolve, this.onReject)
    },
    onReject(error) {
      if (error.code === 'storage/object-not-found') {
        this.upload()
      }
    },
    upload() {
      if (!this.file) {
        this.$Sentry.captureMessage('No file selected, yet upload was triggered')
        return
      }
      this.uploading = true
      this.uploadTask = this.fileRef.put(this.file)
      this.uploadState = 'started'
      this.uploadTask.on('state_changed', this.uploadStateChanged, this.uploadFailed, this.uploadComplete)
    },
    uploadStateChanged(snapshot) {
      this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      switch (snapshot.state) {
        case TaskState.PAUSED:
          this.uploadState = 'paused'
          break
        case TaskState.RUNNING:
          this.uploadState = 'running'
          break
      }
    },
    uploadFailed(error) {
      this.uploadState = 'error'
      this.$store.dispatch('snackbar/new', { error })
      this.$Sentry.captureException(error)
    },
    async uploadComplete() {
      const downloadURL = await this.uploadTask.snapshot.ref.getDownloadURL()
      this.uploadState = null
      this.uploadProgress = null
      this.$emit('input', downloadURL)
    },
    remove() {
      // V2: ask if they want to delete the uploaded asset at this point
      this.file = null
      this.$emit('input', null)
    }
  }
}
</script>
