import Vue from 'vue'

import Panel from '@panel/App.vue'
import router from '@panel/router'
import LayoutAppBar from '@panel/components/layout/AppBar.vue'
import LayoutDrawerDialog from '@panel/components/layout/DrawerDialog.vue'
import LayoutProjectNavigation from '@panel/components/layout/ProjectNavigation.vue'
import '@panel/filters'

import store from '@/store'
import vuetify from '@/plugins/vuetify'
import { auth } from '@/plugins/firebase'
import '@/plugins'
import '@/components'
import '@/filters'
import '@/styles'

Vue.config.productionTip = false

Vue.component('LayoutAppBar', LayoutAppBar)
Vue.component('LayoutDrawerDialog', LayoutDrawerDialog)
Vue.component('LayoutProjectNavigation', LayoutProjectNavigation)

const getAsyncCurrentUser = () => {
  let userLoaded = false
  return new Promise((resolve, reject) => {
    if (userLoaded) {
      resolve(auth.currentUser)
    }
    const unsubscribe = auth.onAuthStateChanged(async(user) => {
      userLoaded = true
      unsubscribe()
      // log user out if demo user (can be signed in on playground)
      if (user && user.email === 'playground@fireful.io') {
        await auth.signOut()
        resolve()
        return
      }
      await store.dispatch('setUser', user)
      resolve()
    }, reject)
  })
}

const appendScripts = () => {
  // <!-- Hotjar Tracking Code for https://panel.fireful.io -->
  (function(h, o, t, j, a, r) {
    h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) }
    h._hjSettings = { hjid: 2218209, hjsv: 6 }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script'); r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')

  // Tidio, stripe and userpilot
  const fjs = document.getElementsByTagName('script')[0]
  for (const src of [
    // 'https://deploy.userpilot.io/83fl7p8.js',
    // 'https://js.stripe.com/v3/',
    '//code.tidio.co/bja4ucpyekptyf4yc1pesnt8evmgvjhn.js'
  ]) {
    const js = document.createElement('script')
    js.async = true
    js.defer = true
    js.src = src
    fjs.parentNode.insertBefore(js, fjs)
  }
}

const initApp = async() => {
  document.title = 'fireful panel'
  appendScripts()
  auth.onAuthStateChanged((user) => {
    store.dispatch('setUser', user)
  })
  await getAsyncCurrentUser()
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(Panel)
  }).$mount('#app')
}
initApp()
