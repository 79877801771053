<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-switch
      v-model="value.counter"
      title="Show Counter"
      description="Displays a character count on the bottom right."
    />
    <template #validations>
      <fire-number
        v-model="value.minLength"
        title="Min Length"
        description="Enter a value to constrain the input to a minimum character length."
        integer
        min-value="1"
        :max-value="value.maxLength"
      />
      <fire-number
        v-model="value.maxLength"
        title="Max Length"
        description="Enter a value to constrain the input to a maximum character length."
        integer
        :min-value="value.minLength || 1"
      />
      <fire-select
        v-model="value.format"
        :items="availableFormats"
        title="Regular Expression"
        hint="Select a commonly used regular expression to limit user input."
        item-value="name"
        item-title="name"
        item-subtitle="description"
        clearable
      />
      <!-- <fire-string
        v-model="value.regex"
        title="Regex"
        description="The field must match the regular expression."
        regex="^/(.*?)/([gimy]*)$"
      /> -->
    </template>
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireStringForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-string'),
    availableFormats: [
      {
        name: 'alpha',
        description: 'The field under validation may only contain alphabetic characters.'
      },
      {
        name: 'alpha_dash',
        description: 'The field under validation may contain alphabetic characters, numbers, dashes or underscores.'
      },
      {
        name: 'alpha_num',
        description: 'The field under validation may contain alphabetic characters or numbers.'
      },
      {
        name: 'alpha_spaces',
        description: 'The field under validation may contain alphabetic characters or spaces.'
      },
      {
        name: 'email',
        description: 'The field under validation must be a valid email.'
      }
    ]
  })
}
</script>
