<template>
  <div class="fire-column--container v-dialog--scrollable">
    <v-card
      flat
      class="fire-column"
      :class="{ 'd-none': $vuetify.breakpoint.xsOnly && $route.params.collectionRef }"
    >
      <v-card-title>
        Collections
        <v-spacer />
        <v-btn
          v-if="panel"
          :to="{ name: 'new-collection' }"
          color="primary"
          icon
        >
          <v-icon v-text="'mdi-plus'" />
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-list>
        <v-list-item
          v-for="(collection, name) in collections"
          :key="name"
          :to="{ name: 'documents', params: { collectionRef: collection.ref } }"
          color="primary"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ collection.plural || `${collection.title}s` }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="panel">
              {{ collection.ref }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-text v-if="panel && !collections.length">
        <!-- NOTE: GOOD ALERT STYLE -->
        <v-alert type="info" text border="left">
          <div class="d-flex flex-wrap justify-space-between align-center">
            <p>Seems like there are no collections defined in your fireful schema. Start by adding a new collection.</p>
            <v-btn class="ml-auto" :to="{ name: 'new-collection' }">
              <v-icon left>
                mdi-plus
              </v-icon>
              New collection
            </v-btn>
          </div>
        </v-alert>
      </v-card-text>
    </v-card>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Collections',
  props: {
    panel: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState('firebase', ['collections'])
  }
}
</script>
