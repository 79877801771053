<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-switch
      v-model="value.showMap"
      title="Show Map"
    />
    <fire-number
      v-if="value.showMap"
      v-model="value.zoom"
      title="Zoom"
      description="Pick a zoom level between 0 and 19."
      integer
      min-value="0"
      max-value="19"
    />
    <fire-geopoint
      v-if="value.showMap"
      v-model="value.center"
      title="Center"
      description="Enter the center coordinates for the map."
      :show-map="false"
    />
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireGeopointForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-geopoint')
  })
}
</script>
