<template>
  <validation-provider
    v-slot="{ errors }"
    :rules="rules"
    :name="label.toLowerCase()"
    tag="div"
    class="fire-input"
  >
    <fire-card
      :key="JSON.stringify(items)"
      :title="label"
      :description="description"
      :disabled="disabled"
      :error-messages="errors"
    >
      <v-checkbox
        v-for="(item, i) in items"
        :key="i"
        v-model="input"
        :value="item.value"
        :label="item.label"
        :hint="item.description"
        :persistent-hint="Boolean(item.description)"
        hide-details="auto"
        color="primary"
        class="ma-2 mb-4"
        :required="required"
      />
    </fire-card>
  </validation-provider>
</template>

<script>
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireChecklist',
  description: 'An array checklist field that holds the values of checked items.',
  icon: 'mdi-checkbox-multiple-marked-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: () => 'checklist'
    },
    title: {
      type: String,
      default: () => 'Checklist'
    },
    items: {
      type: Array,
      default: () => ([
        {
          value: 'a',
          label: 'Example Choice A'
        },
        {
          value: 'b',
          label: 'Example Choice B'
        }
      ])
    }
  },
  computed: {
    // override default input computed property to use empty array instead of null
    input: {
      get() {
        return this.value || []
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
