import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  breakpoint: {
    thresholds: {
      // 'xs': 600,
      // sm: 1060, // 960
      // md: 1280,
      // lg: 1920,
    }
    // scrollBarWidth: 16,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#41b883', // fireful green
        secondary: '#fd676b', // firebase coral'#FF8A65'
        accent: '#2C384A', // firebase navy
        info: '#039BE5', // firebase blue
        warning: '#F57C00', // firebase orange
        firebase: '#FFCA28' // firebase yellow
      }
    },
    options: {
      customProperties: true
    }
  }
})

// firebase amber #FFA000
// firebase grey #ECEFF1
