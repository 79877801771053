<template>
  <v-card flat>
    <v-toolbar dense flat style="contain: initial;">
      <v-btn
        icon
        tile
        :disabled="!relativePath || relativePath === '/'"
        @click="goUpOneDirectory"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-icon left>
        mdi-folder-outline
      </v-icon>
      home<template v-if="relativePath">
        /{{ relativePath }}
      </template>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          text
          tile
          @click.stop="$refs.uploader.click()"
        >
          <v-icon left v-text="'mdi-cloud-upload-outline'" />
          Upload
          <input
            ref="uploader"
            class="fire-input--file"
            type="file"
            multiple
            @change="startUpload"
          >
        </v-btn>
        <v-btn
          text
          tile
          @click="view = view === 'grid' ? 'list' : 'grid'"
        >
          <v-icon left>
            mdi-{{ view === 'grid' ? 'format-list-bulleted' : 'view-grid' }}
          </v-icon>
          {{ view === 'grid' ? 'List' : 'Grid' }}
        </v-btn>
        <!-- <v-btn
          icon
          tile
          @click="newFolderDialog = true"
        >
          <v-icon>
            mdi-folder-plus-outline
          </v-icon>
        </v-btn> -->
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text
      class="pa-2 pa-md-4"
      :class="view === 'grid' ? 'd-flex flex-wrap' : ''"
    >
      <storage-item
        v-for="item in directoryContents"
        :key="item.url"
        :item="item"
        :view="view"
        @click="clickItem(item)"
        @delete="deleteItem(item)"
        @references="showReferences(item.url)"
      />
    </v-card-text>
    <v-card-actions v-if="nextPageToken || loading" class="justify-center">
      <v-btn
        v-if="nextPageToken"
        text
        @click="loadNextPage"
      >
        Load More
      </v-btn>
      <v-progress-circular v-if="loading" indeterminate />
      </v-progress>
    </v-card-actions>
    <v-dialog
      v-model="newFolderDialog"
      max-width="300px"
    >
      <validation-observer
        ref="newFolderForm"
        v-slot="{ valid }"
        tag="form"
        @submit.prevent="createFolder"
      >
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              New Folder
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              @click="newFolderName = '';newFolderDialog = false"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <validation-provider
              v-slot="{ errors }"
              rules="required|folder_unique"
              name="Folder Name"
            >
              <v-text-field
                v-model="newFolderName"
                outlined
                autofocus
                label="Folder Name"
                class="display-1"
                :error-messages="errors"
              />
            </validation-provider>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn
              outlined
              color="primary"
              type="submit"
              :loading="creatingFolder"
              :disabled="!valid || creatingFolder"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </v-dialog>
    <v-dialog
      v-model="referencesDialog"
      width="300px"
    >
      <v-card>
        <v-card-title class="justify-space-between">
          References
          <v-btn
            fab
            text
            class="mt-n2 mr-n4"
            @click="referencesDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-list>
          <fire-document-list-item-new
            v-for="reference in references"
            :key="reference.id"
            :item="reference.item"
            :collection-ref="reference.collectionRef"
            :document-id="reference.id"
          />
        </v-list>
        <v-card-text v-if="!references.length">
          <v-alert type="info" outlined>
            No items are currently using this asset.
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { extend } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { db } from '@/plugins/firebase'
import { unpackDownloadURL } from '@/utils/helpers'
export default {
  name: 'StorageLibrary',
  props: {
    path: {
      type: String,
      default: () => ''
    },
    router: {
      type: Boolean,
      default: () => false
    },
    itemsPerPage: {
      type: Number,
      default: () => 100
    },
    assetType: {
      type: String,
      default: () => null
    },
    storageBucket: {
      type: String,
      default: () => null
    },
    homeDirectory: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      view: window.localStorage.getItem('storage-library-view'),
      relativePath: undefined,
      loading: false,
      subdirectories: [],
      assets: [],
      creatingFolder: false,
      uploader: false,
      deleting: null,
      newFolderName: '',
      newFolderDialog: false,
      referencesDialog: false,
      references: [],
      uploadFileCounter: 0,
      nextPageToken: null
    }
  },
  computed: {
    ...mapGetters(['project']),
    ...mapState('uploader', {
      uploadIndex: state => state.fileIndex
    }),
    ...mapState('firebase', [
      'storage',
      'db'
    ]),
    fullPath() {
      return this.relativePath ? `${this.homeDirectory}/${this.relativePath}` : this.homeDirectory
    },
    storageRef() {
      return this.storage ? this.storage.ref().child(this.fullPath) : null
    },
    directoryContents() {
      return this.subdirectories.concat(this.assets)
    }
  },
  watch: {
    path: {
      handler(path) {
        this.relativePath = path
      },
      immediate: true
    },
    storage: {
      handler() {
        this.initRelativePath()
      },
      immediate: true
    },
    relativePath: {
      handler() {
        this.initRelativePath()
      },
      immediate: true
    },
    view: {
      handler(view) {
        window.localStorage.setItem('storage-library-view', view || 'list')
      },
      immediate: true
    },
    uploadIndex() {
      this.syncAssets()
    }
  },
  beforeMount() {
    extend('folder_unique', this.folderUnique)
  },
  methods: {
    folderUnique(value) {
      return this.subdirectories.every(x => x.meta.name !== value) || 'A folder with this name already exists'
    },
    initRelativePath() {
      if (!this.storageRef) {
        return
      }
      // clear assets
      this.assets = []
      this.subdirectories = []
      // get the storage folders saved in database
      this.syncAssets()
    },
    async syncAssets() {
      this.loading = true
      // get the path contents from firebase storage
      // https://firebase.google.com/docs/storage/web/list-files
      const { prefixes, items, nextPageToken } = await this.storageRef.list({ maxResults: this.itemsPerPage })
      const storagePrefixes = prefixes.map(x => ({
        url: x.fullPath,
        meta: {
          type: 'folder',
          name: x.name
        }
      }))
      // add folders that were not saved in database but exist in firebase storage
      const prefixPaths = this.subdirectories.map(x => x.url)
      this.subdirectories = this.subdirectories.concat(storagePrefixes.filter(x => !prefixPaths.includes(x.url)))

      // load the assets
      let assets = await Promise.all(items.map(async(item) => {
        item.url = await item.getDownloadURL()
        item.meta = await item.getMetadata()
        return item
      }))
      if (this.assetType) {
        assets = assets.filter(this.typeMatch)
      }
      this.assets = assets
      // save next page token
      this.nextPageToken = nextPageToken
      this.loading = false
    },
    async loadNextPage() {
      const pageToken = this.nextPageToken
      this.nextPageToken = null
      this.loading = true
      const { prefixes, items, nextPageToken } = await this.storageRef.list({ maxResults: this.itemsPerPage, pageToken })

      // add folders
      if (prefixes.length) {
        const storagePrefixes = prefixes.map(x => ({
          url: x.fullPath,
          meta: {
            type: 'folder',
            name: x.name
          }
        }))
        const prefixPaths = this.subdirectories.map(x => x.url)
        this.subdirectories = this.subdirectories.concat(storagePrefixes.filter(x => !prefixPaths.includes(x.url)))
      }

      let pageAssets = await Promise.all(items.map(async(item) => {
        item.url = await item.getDownloadURL()
        item.meta = await item.getMetadata()
        return item
      }))
      if (this.assetType) {
        pageAssets = pageAssets.filter(this.typeMatch)
      }
      this.assets = this.assets.concat(pageAssets)
      this.nextPageToken = nextPageToken
      this.loading = false
    },
    typeMatch(item) {
      let contentType = this.assetType
      if (contentType === 'document') {
        contentType = 'application'
      }
      return item.meta.contentType.includes(contentType)
    },
    async createFolder() {
      this.creatingFolder = true
      // create folder path that always starts with /
      // const newFolderPath = `${this.relativePath}/${this.newFolderName}`
      // temporary unique validation
      // const storageDirectories = [...new Set((this.organization.storageDirectories || []).concat(newFolderPath))]
      // await db.collection('organizations').doc(this.organization.id).update({ storageDirectories })
      this.newFolderName = ''
      this.newFolderDialog = false
      await this.syncAssets()
      this.creatingFolder = false
    },
    clickItem(item) {
      if (item.meta.type === 'folder') {
        if (this.router) {
          // if path control is via router, leave handling to parent page component
          return this.$emit('path-change', item.url)
        }
        return this.goToPath(item.url)
      }
      this.$emit('select', item)
    },
    goToPath(path) {
      // go to the relative directory under homeDirectory
      this.relativePath = path
    },
    goUpOneDirectory() {
      const pathArr = this.relativePath.split('/')
      pathArr.pop()
      const newPath = pathArr.join('/')
      if (this.router) {
        // if path control is via router, leave handling to parent page component
        return this.$emit('path-change', newPath)
      }
      return this.goToPath(newPath)
    },
    async deleteItem(item) {
      if (item.meta.type === 'folder') {
        // check if folder contents have any assets
        const { prefixes, items } = await this.storageRef.child(item.meta.name).list({ maxResults: 1 })
        if (prefixes.length || items.length) {
          // LATER: Allow deletion of non-empty folders
          return alert('Non empty folders cannot be deleted at this time. Please first delete the items inside the folder.')
        }
        // remove folder from storageDirectories
        const storageDirectories = [...new Set((this.organization.storageDirectories || []).filter(x => x !== item.url))]
        await db.collection('organizations').doc(this.organization.id).update({ storageDirectories })
        await this.syncAssets()
        return this.$store.dispatch('snackbar/new', { type: 'success', message: 'Folder deleted.' })
      }
      const { url } = item
      const references = await this.findReferences(url)
      if (references.length) {
        // LATER: Show referencing documents
        return alert("You can't delete this file as there are documents referencing it")
      }
      this.deleting = true
      const assetRef = this.storage.refFromURL(url)
      assetRef.delete()
        .then(() => {
          this.deleting = false
          const item = this.assets.find(x => x.url === url)
          const index = this.assets.indexOf(item)
          this.assets.splice(index, 1)
          this.$store.dispatch('snackbar/new', { type: 'success', message: 'Asset deleted.' })
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/new', { error })
          this.$Sentry.captureException(error)
        })
        .finally(() => {
          this.deleting = false
        })
    },
    async showReferences(url) {
      /// ////////////////////////
      // MVP: Fetch references //
      // Go to storage references in firestore and list them here
      /// ////////////////////////
      this.references = await this.findReferences(url)
      this.referencesDialog = true
    },
    async findReferences(url) {
      const { fullPath } = unpackDownloadURL(url)
      const references = await db
        .collection(`projects/${this.project.id}/audits`)
        .where('storageReferences', 'array-contains', fullPath)
        .get()
      return Promise.all(references.docs.map((doc) => {
        const data = doc.data()
        return this.db.collection(data.collectionRef).doc(doc.id).get()
          .then(d => ({
            id: d.id,
            collectionRef: data.collectionRef,
            item: d.data()
          }))
      }))
    },
    startUpload(event) {
      this.$store.dispatch('uploader/selectFiles', {
        files: event.target.files,
        path: this.fullPath
      })
    }
  }
}
</script>
