<template>
  <fire-document-list-item
    :item="item"
    :fields="collection.fields"
    :item-avatar="collection.listPreviewAvatar"
    :item-title="collection.listPreviewTitle"
    :item-subtitle="collection.listPreviewSubtitle"
    :to="{ name: 'document', params: { collectionRef, documentId } }"
  />
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'FireDocumentListItemNew',
  props: {
    documentId: {
      type: String,
      default: () => ''
    },
    collectionRef: {
      type: String,
      default: () => ''
    },
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('firebase', ['collections']),
    collection() {
      return this.collections.find(x => x.ref === this.collectionRef)
    }
  }
}
</script>
