<template>
  <fire-field-form
    v-model="value"
    :field="field"
    :hide-field-name="hideFieldName"
    :exclude-field-names="excludeFieldNames"
  >
    <fire-array
      v-model="value.items"
      title="Item"
      description="Configure a list of items for the user to choose from."
    >
      <template #[`item.edit`]="{ item }">
        <fire-string
          v-model="item.value"
          title="Item value"
          description="The value that will be saved in firebase."
          required
        />
        <fire-string
          v-model="item.title"
          title="Item title"
          description="The text that will be displayed to the user."
        />
        <fire-string
          v-model="item.subtitle"
          title="Item subtitle"
          description="Further description if necessary."
        />
        <fire-string
          v-model="item.icon"
          title="Item icon"
          description="Material design icon."
        />
      </template>
    </fire-array>
    <fire-switch
      v-model="value.clearable"
      title="Clearable"
      description="Add input clear functionality."
    />
  </fire-field-form>
</template>

<script>
import { fieldFormsMixin } from '@/mixins'
import fields from '@/components/fields'
export default {
  name: 'FireSelectForm',
  mixins: [fieldFormsMixin],
  props: {
    value: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    field: fields.find(x => x.name === 'fire-select')
  }),
  mounted() {
    if (!this.value.itemValue) {
      this.value.itemValue = 'value'
    }
    if (!this.value.itemTitle) {
      this.value.itemTitle = 'title'
    }
    if (!this.value.itemSubtitle) {
      this.value.itemSubtitle = 'subtitle'
    }
    if (!this.value.itemIcon) {
      this.value.itemIcon = 'icon'
    }
  }
}
</script>
