<template>
  <fire-select
    v-model="input"
    :items="documents"
    :title="title"
    :description="description"
    :disabled="disabled"
    :required="required"
    :clearable="clearable"
    item-value="id"
    :item-title="listPreviewTitle || 'id'"
    :item-subtitle="listPreviewSubtitle"
    :item-icon="avatarIcon ? listPreviewAvatar : null"
    :item-image="!avatarIcon ? listPreviewAvatar : null"
  />
</template>

<script>
// LATER: Reference search by field to query and pagination details for dropdown
import { mapState } from 'vuex'
import { getFirestoreDoc } from '@/plugins/firebase'
import { fieldsMixin } from '@/mixins'
export default {
  name: 'FireReference',
  description: 'A firestore document reference field.',
  icon: 'mdi-link-box-variant-outline',
  mixins: [fieldsMixin],
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: () => 'reference'
    },
    title: {
      type: String,
      default: () => 'Reference'
    },
    to: {
      type: String,
      default: () => ''
    },
    listPreviewTitle: {
      type: String,
      default: () => ''
    },
    listPreviewSubtitle: {
      type: String,
      default: () => ''
    },
    listPreviewAvatar: {
      type: String,
      default: () => ''
    },
    clearable: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    documents: [],
    unsubscribe: null
  }),
  computed: {
    ...mapState('firebase', ['db', 'collections']),
    input: {
      get() {
        return this.value.id
      },
      set(value) {
        const documentRef = value ? this.db.collection(this.to).doc(value) : undefined
        this.$emit('input', documentRef)
      }
    },
    collectionFields() {
      return this.collections.find(x => x.ref === this.value.to)?.fields || []
    },
    avatarIcon() {
      return this.collectionFields.find(x => x.name === this.listPreviewAvatar)?._type === 'fire-icon'
    }
  },
  watch: {
    to: {
      handler(ref) {
        this.unsubscribe && this.unsubscribe()
        const collection = this.collections.find(c => c.ref === ref)
        if (!collection) {
          return
        }
        this.unsubscribe = this.db.collection(collection.ref)
          .onSnapshot(({ docs }) => {
            this.documents = docs.map(getFirestoreDoc)
          })
      },
      immediate: true
    }
  }
}
</script>
