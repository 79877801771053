<template>
  <validation-observer
    ref="form"
    v-slot="{ valid }"
    tag="form"
    @submit.prevent="save"
  >
    <v-card-text
      class="mb-0 pt-3"
    >
      <fire-string
        v-model="form.ref"
        title="Collection Ref"
        description="The collection reference path. Can be an upper level collection or a nested one."
        required
        autofocus
        unique
      />
      <fire-string
        v-model="form.title"
        title="Collection Title"
        description="Enter a singular title for the items in this collection."
        required
        unique
      />
      <fire-string
        v-model="form.plural"
        title="Plural Title"
        :placeholder="form.title ? `${form.title}s` : ''"
        :description="form.title ? `Enter if you would like to use something other than '${form.title}s'` : ''"
      />
      <!-- <fire-switch
        v-model="form.keepHistory"
        title="History (Changelog)"
        description="Enable this to keep a changelog for documents that you can use to restore."
        :allow-indeterminate="false"
      /> -->
      <!-- <fire-switch
        v-model="form.allowCreate"
        title="Allow Creating New Items"
        :disabled="!plan"
        description="For editors only. Admins are always allowed to create items."
        :allow-indeterminate="false"
      />
      <fire-switch
        v-model="form.allowDelete"
        title="Allow Deleting Items"
        :disabled="!plan"
        description="For editors only. Admins are always allowed to delete items."
        :allow-indeterminate="false"
      /> -->
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        type="submit"
        text
        :loading="saving"
        :disabled="!valid || saving"
      >
        Save
      </v-btn>
    </v-card-actions>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import { db, Timestamp } from './../plugins/firebase'
export default {
  name: 'CollectionSetup',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    form: {
      keepHistory: true
    },
    saving: false,
    mockFormData: {}
  }),
  computed: {
    ...mapGetters(['project'])
  },
  watch: {
    value: {
      handler(value) {
        if (value?.id) {
          this.form = JSON.parse(JSON.stringify({ id: value.id, ...value }))
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async save() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      this.saving = true
      try {
        const { id } = await db
          .collection(`projects/${this.project.id}/collections`)
          .add({
            createdAt: Timestamp.fromDate(new Date()),
            ...this.form
          })
        this.$emit('saved', id)
        this.$store.dispatch('snackbar/new', { type: 'success', message: 'Collection saved' })
      } catch (error) {
        this.$store.dispatch('snackbar/new', { error })
        throw error
      } finally {
        this.saving = false
      }
    }
  }
}
</script>
